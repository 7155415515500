// 设置 rem 函数
function setRem () {
    //  PC端
    // console.log('非移动设备')
    // 基准大小
    var baseSize = 1;
    let basePc = baseSize*100 / 1440; 
    let vW = window.innerWidth; // 当前窗口的宽度
    var rem;
    if(vW>=1280){
        rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
    }else if(vW>=950){
        rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
    }else{
        rem = 950 * basePc;
    }
    document.documentElement.style.fontSize =  rem + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
};