module.exports = {
	common: {
		title:'라이트',
		endtime:'유효시간',
		contact: '연락하기',
		exchange:'거래소',
		pair:'거래쌍',
		opensea:'NFT 토큰',//collection
		currency:'화폐종류',
		available:'사용 가능 한',
		frozen:'동결',
		change:'변동',
		total:'합계',
		currentOpen:'현재 디스크',
		collection:'현재 디스크를 모음함',
		commonly:'상용 디스크',
		explain:'설명',// XX로봇 설명 / XX도구 설명
		addTile:'추가',// XX로봇 추가
		editTitle:'편집',// XX로봇 편집
		description:'기능 설명',
		num:'수량',
		pirce:'가격',
		purchase:'매입',
		sellout:'매출',
		closed:'거래가 성립되다',
		direction:'방향',
		time:'시간',
		operation:'조작',
		recharge:'충전',
		withdrawal:'인출',
		exchangeSelect:'거래소 선택',
		whole:'전부',
		agreement:'사용자 협의',
		mainAddress:'마스터 지갑 주소',
		subAddress:'인증되지 않은 주소',
		gasCurrency:'gas화폐 종류',
		token:'라이센스 토큰',
		filter:'압축',
		content:'내용',
		selectTime:'시간을 선택함',
		valuation:'가치평가',
		proportion:'비율',
		gas:'수수료',
		targetAddress:'대상 주소',
		balance:'잔고',

		// 按钮
		renew:'요금',
		open:'개통',
		handicap:'핸디캡에 들어가다',
		into:'들어가다',
		selectOpen:'핸디캡을 선택함',
		back:'돌아가기',
		next:'다음 단계',
		pre:'이전 단계',
		continueAdd:'계속 추가',
		add:'추가',
		edit:'편집',
		modify:'수정',
		bind:'바인딩',
		remove:'해제',
		removeBind:'바인딩 해제',
		toOpen:'개통하기 위해',//add
		backlist:'반환 목록',
		search:'검색',
		confirm:'확정',
		beforeDay:'전날',
		nextDay:'다음 날',
		submit:'제출',
		cancel:'취소',
		confirm2:'확인',
		save:'저장',
		saveRun:'저장하고 시작',
		copy:'복사',
		cancelOrder:'취소',
		stop:'중지',
		run:'가동',
		del:'삭제',
		toBind:'바인딩하기',
		toAuthorize:'권한 부여',
		authorize:'수권',
		toAllocation:'파견을 나가기',
		toErrLog:'오류 로그 보기',
		allotAuthorize:'할당 위임',
		directAuthorize:'직접 인증',
		ignore:'무시',
		viewBtn:'보기',
		refused:'모질게 거절합니다',
		update1:'지금 업데이트',
		update2:'지금 업데이트',
		downLoad:'내보 내기',
		// 单选
		yes:'네', 
		no:'아니요',

		// 输入框占位符
		select:'선택하십시오',
		input:'입력하십시오',
		value:'수치',
		minValue:'최소값',
		maxValue:'최대치',
		inputVerification:'인증번호를 입력해주세요',
		selectdate:'날짜를 선택합니다',
		startdete:'시작 날짜',
		enddete:'종료 날짜',
		keyword:'검색어를 입력하세요',


		telegram:'Telegram',
		pwd:'암호',
		email:'메일 박스',
		emailAddress:'메일박스 주소',
		// newPhone:'새 핸드폰',
		newPwd:'새 비밀번호',
		confirmNewPwd:'새 비밀번호 확인',
		newEmail:'새 메일박스',
		verification:'메일박스 인증 코드',
		verification_s:'인증 코드',
		countDown:'카운트다운',
		sendVerification:'인증 코드 보내기',
		resend:'다시 보내기',

		max:'최대',
		min:'최소',

		// sub
		userName:'사용자 이름',
		login:'로그인',
		register:'등록',

		documentation:'문서',
		freeRegistration:'무료 등록',

		loding:'불러오는 중',
		noMore:'더 이상은 없습니다',
		noData:'불충분한 증거',

		subManage:'지갑 관리',
		toSeeBtn:'확인하러 가다',
		enabled:'활성화됨',
		notEnabled:'사용이 중지되다',

		// 交易记录
		transactionRecord:'거래 기록',
		address:'주소',
		tradingDirection:'거래 방향',
		tradingAddress:'지갑 주소',
		transactionAverage:'가격',
		transactionNum:'거래건수',
		transactionAmount:'거래금액',
		orderStatus:'주문 상태',
		orderStatus1:'거래 실패',
		orderStatus2:'거래 성공',
		orderStatus3:'거래중',
		viewTX:'거래 확인',
		
		query:'조회',
		toLogin:'로그인',
		loginAgain:'다사 로그인',
		refresh:'새로 고침',
		toClose:'닫기'
	},
	head:{
		oldWeb:'구판 라이트5.0 시스템 입구',
		// dayMode:주간 모드',
		// nightMode:'야간 모드',
		lv:'사용자 레벨',
		center:'사용자 센터',
		invite:'친구를 초대하기',
		notice:'공고',
		out:'로그아웃',
		outTip:'로그아웃을 하시겠습니까？',
	},
	aside:{
		list:[
			'계정 목록',
			'수동 트레이더',
			'딜러 로봇',
			'로봇 로그',
			'데이터 보고서',
			'자산 보고서',
			'경보시스템',
			'사용자 설정',
			'사용자 센터',
			'친구를 초대하기',
			'지지',
			'초보 가이드',
			'연락하기',
		],
		renew:'요금을 연장하여 개통하기',
	},
	// 官网相关
	website:{
		index:{
			lightMM:'Lightning',
			// mm:'시장가치관리로봇',
			mm:'',
			sub:' 6년 동안 안정적으로 운영되었으며 현재 세계에서 가장 안전하고 강력한 시장가치 시스템입니다. 모든 주요 덱스 (dex)를 지원합니다!',
			project:'프로젝트 측',
			multiChain:'멀티 체인 Dex를 지원함',
			// f2
			fullyAutomatic:'전자동 가격변동',
			followSystem:'시세추적시스템',
			sellSuck:'비싸게 팔고 싸게 사들여 차익을 얻기',
			assetWarning:'자산경보시스템',
			// f3
			packages:'패키지',
			recommend:'추천',
			month:'월',
			// f4
			features:'제품 특징',
			featuresDetail:'안전, 강력, 편리',
			safe:'안전',
			safeSub:'자산은 거래소에 입금되며, 계정 정보는 여러 번 암호화됩니다.',
			power:'강력',
			powerSub:'라이트 로봇의 핵심 프로그램과 논리는 수년간의 연구와 개발을 가지고 있습니다.',
			convenient:'편리',
			convenientSub:'여러 계정의 집계 관리를 통해 여러 터미널에서 자유롭게 운영할 수 있습니다.',
			efficient:'효율적',
			efficientSub:'효율적인 작동.',
			// f5
			customers:'타겟 고객',
			who:'우리는 누구에게 서비스를 제공합니까?',
			market:'마켓 메이커',
			incubator:'부화 기구',
			// foot
			links:'링크',
			// storm:'폭풍 주문 추적',
			// lightning:'Lightning',
			follow:'Follow',
			service:'서비스',
			cooperation:'비즈니스 협력',
			problem:'자주하는 질문',
			terms:'조항 설명',
			privacy:'프라이버시',
		},
		agreement:{
			protocol:'사용자 협의',
			content:`
			<p>라이트 시스템은 Singapore Jumu Capital co. ltd.에 의해 개발되고 운영된다. 회사의 웹사이트 http://www.llkkz.net (이하 "이 웹사이트" )은 블록체인 산업 서비스(이하 "서비스") 플랫폼을 제공하는 서비스입니다. 귀하의 권리를 보호하기 위해, 이 웹사이트를 등록하거나 사용하기 전에 사용자 프로토콜의 전체 내용을 읽고 완전히 이해하십시오.
			</p><br/>
			<h3>첫째、총칙</h3>
			<p>
				1.1 사용자 프로코톨(이하 "프로토콜")은 본문, 개인 정보 보호 정책 및 이 웹사이트에 게시되었거나 향후 게시될 수 있는 다양한 규칙, 진술, 지침 등으로 구성됩니다. <br/>

                             1.2 이 웹사이트에서 제공하는 서비스를 사용하기 전에, 이 프로토콜을 주의 깊게 읽어야 합니다. 이해력이나 다른 필요성이 있다면, 전문 변호사와 상담하세요. 언제든지 본 계약 및/또는 수정에 동의하지 않으면, 이 웹사이트에서 제공하는 서비스 사용을 중단하거나 즉시 이 웹사이트에 로그인 하십시오.
. <br/>
				1.3 이 웹사이트의 요구 사항에 따라 관련 정보를 작성하고 다른 관련 절차를 통해 성공적으로 등록함으로써 이 웹사이트의 사용자(이하 "사용자")가 될 수 있습니다.
등록 과정에서 "동의" 버튼을 클릭하면 전자 서명이 됩니다. 또는 이 웹사이트를 사용하는 과정에서 "동의" 또는 이와 유사한 버튼을 클릭하거나, 이 웹사이트에서 허용하는 다른 방식으로 이 웹사이트에서 제공하는 서비스를 실제로 사용할 때, 당신은 이 프로토콜에 따른 모든 약관을 완전히 이해하고 동의하며 동의하게 됩니다. 이 계약에 관한 한, 손으로 쓴 서면 서명의 부재는 법적 구속력에 영향을 미치지 않습니다. <br/>
				1.4 이 웹사이트의 사용자가 되면, 사용자 계정과 해당 비밀번호를 얻게 되며, 사용자 계정과 비밀번호는 사용자가 보관해야 합니다. 당신은 당신의 계정에서 발생하는 모든 활동과 사건에 대해 법적 책임이 있습니다. <br/>

                            1.5 이 웹사이트의 사용자가 된 사용자만 이 웹사이트에서 제공하는 블록체인 산업 서비스를 사용할 수 있습니다; 등록되지 않은 사용자는 웹사이트의 콘텐츠만 탐색할 수 있습니다. <br/>
   
                            1.6 이 웹사이트에서 제공하는 서비스와 기능을 등록하고 사용함으로써, 당신은 읽고, 이해하고, 다음을 읽은 것으로 간주됩니다. <br/>

                            1.6.1 이 프로토콜의 모든 이용 약관에 동의하세요.<br/>

                             1.6.2 귀하는 18세 이상이거나 다른 관련 법률에 따라 계약을 체결할 수 있는 법적 연령임을 확인합니다. 이 웹사이트의 서비스를 수락하기 위한 귀하의 등록, 정보 게시 등은 귀하에 대한 관할권을 가진 주권 국가 또는 지역의 관련 법률과 규정을 준수하며, 본 약관을 완전히 수락할 수 있습니다. <br/>

                            1.6.3 등록 중에 제공된 정보가 사실이고 정확하다는 것을 확인합니다.<br/>
                            1.6.4 당신은 관련 법률을 준수하는 것에 동의합니다. <br/>
    
                            1.6.5귀하는 이 웹사이트에서 제공하는 서비스와 관련하여 이 웹사이트나 라이트 로봇을 손상시키는 행위나 활동에 참여하거나 참여해서는 안 된다는 데 동의합니다.
			</p>
			<br/>
			<h3> 둘째、합의 수정</h3>

<p>2.1 라이트닝은 때때로 이 프로토콜을 수정하고 별도로 통지하지 않고 웹사이트 발표의 형태로 발표할 권리를 보유합니다. 변경된 프로토콜이 웹사이트에 게시되면, 즉시 자동으로 적용됩니다. 때때로 이 프로토콜의 업데이트 시간과 업데이트 시간을 탐색하고 주의해야 합니다.
관련 변경 사항에 동의하지 않으면, 즉시 이 웹사이트의 서비스 사용을 중단해야 합니다. 이 웹사이트의 서비스를 계속 사용한다면, 개정된 프로토콜을 수락하고 동의한다는 것을 의미합니다.
			</p>`,
		},
		problem:{
			question1:'시스템은 계정 자산의 보안을 어떻게 보장합니까?',
			answer1:'이중 보호: 1. 가벼운 로봇에는 다양한 공격과 차익거래 행동을 다루는 여러 보호 모듈이 있습니다. 2. 경보 시스템은 설정에 따라 패널을 모니터링할 수 있으며, 이상이 있는 경우 모든 로봇을 즉시 중지합니다.',
			question2:'하나의 라이트 시스템 계정을 여러 거래소 시장 마킹 계좌에 연결할 수 있습니까?',
			answer2:'라이트 시스템은 현재 60개 이상의 교환을 지원합니다. 단일 라이트 계정에서 여러 거래소의 거래 계좌를 바인딩하여 여러 계정의 집계 관리를 완료할 수 있습니다.',
			question3:'자산손익을 어떻게 확인합니까？',
			answer3:'라이트 시스템은 매일 0시에 오늘의 계정 잔액을 기록할 것입니다. 일일 잔고 변화를 비교하여 역사적인 일일 이익과 손실을 확인할 수 있습니다.',
			question4:'비밀 번호를 잊어 버리면 어떻게 찾을 수 있습니까?',
			answer4:'고객 서비스에 문의하십시오. 고객 서비스가 계정 소유권을 확인하고 로그인 비밀번호를 재설정할 것입니다. 로그인 후, 개인 센터에서 비밀번호를 변경할 수 있습니다.',
			question5:'기타',
			answer5:'제품에 대한 사용 문제나 제안이 있다면, 우리는 당신이 저희에게 연락하는 것을 매우 환영합니다, 우리는 당신의 필요를 충족시키기 위해 최선을 다할 것입니다! 라이트 로봇을 지원해 주셔서 감사합니다.',
		},
		contact:{
			text1: '라이트 클라우드 시스템은 2016년 싱가포르 라이트 테크가 암호화폐의 성숙한 거래 기술을 요약하기 위해 개발했으며, 설계하고 개발하는 데 1년이 걸렸다. 암호화폐에서 가장 전문적인 시장 조성/무역 로봇 공급업체로서, 이 시스템은 6년 동안 운영되어 수백 개의 프로젝트 측 시장 조성 팀에 서비스를 제공해 왔습니다.',
            text2: '라이트 클라우드 시스템은 현재 200개 이상의 CEX를 지원합니다. 최신 {version} 시스템은 여러 덱스를 지원하는 데 앞장서 업계 최고의 시장 가치 로봇 서비스 제공 업체가 되었다. 다양한 시스템 전략과 기능을 사용할 수 있으며, 시장 조성/거래를 위한 최선의 선택입니다.',
			business:'비즈니스 협력',
			service:'고객서비스',
		}
	},
	// 登录相关模块
	login:{
		loginTitle:'로그인 라이트',
		forget:'비밀번호를 잊어버리셨습니까？',
		toRegister:'계좌번호 없습니까?등록하러 가세요',
		agree:'동의',
		version:'버전',
		// 忘记密码
		reset:'비밀번호 초기화',
		confirm:'비밀번호 확인',
		confirmReset:'초기화 확인',
		// 注册
		register:'가입 계정',
		toLogin:'계좌번호 있으니 로그인 하세요',
		// 安全登录
		safeLogin:'보안 로그인 인증',
	},
	// 提示
	tip:{
		usernameEmpty:'사용자 이름을 입력하세오',
		accountVer:'사용자 이름은 반드시 영문과 숫자를 포함하여 6자리 이상이어야 합니다',
		// phoneEmpty:'핸드폰 번호를 입력하세요',
		// phoneVer:'정확한 휴대전화 번호를 입력해 주세요',
		emailEmpty:'편지함을 입력하십시오',
		emailVer:'정확한 우편함을 입력해 주세요',
		codeEmpty:'인증번호를 입력해주세요',
		pwdEmpty1:'비밀번호를 입역하세요',
		pwdEmpty:'새 비밀번호를 입력하십시오',
		pwdVer:'비밀번호는 영문과 숫자를 포함하여 모두 6자리 이상이어야 합니다',
		pwd2:'비밀번호가 일치하지 않습니다',
		commonVer:'6자리 이상으로, 반드시 문자와 숫자를 포함해야 합니다',
		again:'새 비밀번호를 다시 한 번 확인해 주세요',
		invite:'초청 코드 (선택)',
		check:'사용자 프로토콜을 체크해야 합니다',
		tips:'제시',
		expired:'권한이 만료되었습니다. 갱신하신 후에 사용하십시오！',
		waiting:'기능 개발 중이오니 기대해주세요！',
		registerSuc:'등록에 성공했습니다！',
		copysuccess:'복사에 성공했습니다！',
		copyerr:'이 브라우저는 복사를 지원하지 않습니다！',
		// 收藏
		collectionSuc:'소장 성공',
		cancelCollectionSuc:'소장 취소 성공',
		// 账号列表
		accountEmpty:'계좌이름 입력해주세요！',
		addSuc:'추가 성공！',
		addErr:'추가 실패！',
		delTip:'삭제하시겠습니까?',
		delSuc:'삭제 성공！',
		delErr:'삭제 실패！',
		// 手动操盘
		selectExchange:'거래소를 선택하세요',
		enterPair:'거래 쌍을 입력하세오',
		enterCollect:'NFT 토큰 입력하세요',//'소장품명을 입력하세요',
		numEmpty:'개수를 입력하세요！',
		priceEmpty:'가격을 입력하세요！',
		buySuc:'매입 성공！',
		sellSuc:'매출 성공！',
		handicap:'핸디캡 들어가세요！',
		cancelOrder:'주문취소 확정인가요?',
		cancelSuc:'취소 성공！',
		manuallyBuy:'수동 매수 확정이요?',
		manuallySell:'수동 팔기를 확인하세요?',
		needSell:'{num} 를 매입해야 한다',
		needBuy:'{num} 를 팔아야 한다',
		total500:'총량은 500 초과할 수 없습니다',
		// 交易记录
		recordListTip:'3개월 동안의 기록만 보여 준다',
		// 子钱包管理
		privateKey:'서브지갑의 개인 전용 키를 입력하세요！',
		toSee:'자세한 결과는 일지를 봐주세요！',
		// searchEmpty:'이 주소는 서브지갑에 없습니다',
		toMainRefresh:'메인 지갑 새로 고치시겠습니까？',
		createSuc:'<div>서브지갑 생성 성공！<br/><span style="color:#E65B1B">【참고: 서브지갑을 사용하시면 즉시 서브지갑에 자금을 분배해 주세요!】</span></div>',
		// 操盘机器人
		saveSuc:'저장 성공！',
		saveErr:'저장 실패！',
		startRobot:'로봇 작동시키시겠습니까?',
		startSuc:'가동 성공！',
		startErr:'가동 실패！',
		stopRobot:'로봇을 정지하면 문제가 발생할 수 있습니다. 로봇을 정지시키시겠습니까?',
		stopSuc:'중지 성공！',
		stopErr:'중지 실패！',
		dataErr:'인자 불완전함！',
		operationSuc:'작업 성공！',
		toRefresh:'잔액 새로 고침 확인하십니까？',
		refreshSuc:'새로 고침 성공！',
		processing:'전송이 성공했습니다!시스템이 처리 중',
		calculationSuc:'계산이 성공함：',
		noRobot:'실행 중인 로봇 없음！',
		closingErr:'닫는 중입니다. 잠시만 기다려 주십시오...',
		closing:'{time}s 이후에는 자동으로 꺼진다',
		mainSaveRunTip:'메인 지갑의 현재 거래 권한이 활성화되어 있습니다. 로봇이 설정되면, 메인 지갑이 거래에서 주문할 가능성이 있다. 거래에 관련된 주요 지갑을 원하지 않는다면, 이 기능을 비활성화하고 로봇을 다시 시작하세요.',
		mainRunTip:'메인 지갑의 거래 권한이 활성화되며, 메인 지갑은 주문에 참여할 것이다. 메인 지갑이 거래에 관여하는 것을 원하지 않는다면, [서브 지갑 관리]로 이동하여 거래 권한을 비활성화하십시오.',
		// 数据报表
		exportSend:'거래 명세는 나중에 메일함으로 보내질 것입니다',
		sendSuc:'발송 성공, 확인에 주의하세요!',
		noEmail:'현재 메일박스를 바인딩하지 않았습니다. 메일박스를 바인딩한 후 사용하십시오',
		num0:'수량이 0이 되어서는 안 된다',
		totalQuantity0:'총 수량이 0이 되어서는 안 된다',
		// 资产报表
		export:'{exchange} 보고서 내보내기',
		// 报警系统
		noTeleram:' Telegram에 바인딩하지 않고 Telegram에 바인딩하다',
		startAlarm:'경보기를 작동시키시겠습니까?',
		stopAlarm:'경보기를 정지하면 문제가 발생할 수 있습니다. 경보기를 정지시키시겠습니까?',
		// 续费开通
		// discountTip:'2022년 11월 1일부터 2022년 12월 30일 지갑 시스템을 이용하실 경우 95 할인 혜택을 받으실 수 있습니다',//活动折扣提示
		discountTip1:'일상활동: 분기지불시 20% 할인, 반년지불시 30% 할인, 년지불시 60% 할인.',
		// discountTip2:'기한부 활동: 2022년 11월 1일부터 2022년 12월 30일 지갑 시스템을 이용하실 경우 95 할인 혜택을 받으실 수 있습니다.',
		renewalSuc:'성공했습니다!',
		// renewalErr:'재생 실패!',
		renewalInsufficient:'재충전 실패, 당신의 지갑 잔액이 부족합니다, 충전하십시오!',
		setPackageSuc:'세트 설정 성공!',
		// setPackageErr:'세트 설정 실패!',
		setPackageInsufficient:'패키지상품 수정에 실패했습니다. 귀하의 지갑 잔액이 부족합니다. 충전하시기 바랍니다!',
		txSeachErr:'hash 갱신이 아직 동기화되지 않았습니다. 나중에 다시 시도하십시오!',
		txSeachErr2:'hash 갱신이 아직 동기화되지 않았습니다. 나중에 다시 시도하십시오!',
		notSupported:'번개 지갑은 볼 수 없습니다!',
		withdrawRestrictions:'현금 인출 금액은 최소 50usdt！',
		addressEmpty:'현금 인출 주소는 비어 있으면 안 됩니다！',
		VerificationEmpty:'인증 코드가 비어 있을 수 없습니다！',
		
		withdrawalSuc:'당신의 현금 인출 신청은 이미 제출되었으니, 현금 인출 기록에서 신청 상태를 살펴보세요',
		// withdrawalErr:'인출 실패',

		editSuc:'수정 성공！',
		editErr:'수정 성공！',

		// 用户中心
		emailRepeat:'새 메일함이 이전 메일함과 중복됩니다！',
		notBindEmail:'먼저 편지함을 바인딩하세요！',
		ipClose:' IP 인증 계정을 종료하면 위험이 있습니다. 종료하시겠습니까？',
		ipCloseTip:'【 참고: 종료 후 1주일 동안은 IP인증이 면제됩니다. 1주일 후에는 자동으로 다시 켜집니다. 】',
		remove:'텔레그램이 성공적으로 바인딩을 해제했습니다!',

		// req
		ipErr:'현재 IP가 유효하지 않습니다. 사용 이상을 방지하기 위해 다시 로그인하십시오！',
		unknownErr:'시스템이 사용 중입니다. 나중에 다시 시도해 주시기 바랍니다',//'알 수 없는 오류',
		// 1003 1004
		update:'내용을 갱신하다：',
		// 2015
		ipVerTip:'【참고: 사용자 센터로 가서 IP인증을 종료할 수 있음 (추천하지 않음)】',

		confirmAuthorize:'Dex지갑 라이선스 토큰을 확인하십시오：',
		authorizeSuc:'인증에 성공했습니다. 다시 수행하십시오！',
		mainAuthorizeErr:'【참고: 계정주 지갑의 가스 (gas) 가 충분한지 확인해주세요.】',
		authorizeErr:'【참고: 가스 부족으로 지갑 인증에 실패했습니다. 지갑 관리 인터페이스로 가서 실패한 지갑 가스가 충분한지 확인하십시오】',
		// 2109
		authorizeErr2:'【참고 1:gas 가 부족하여 지갑 인증에 실패했습니다. 분할 인증을 클릭하거나 지갑 관리 인터페이스로 가서 지갑의 gas 잔액을 확인하십시오.】<br/>【참고 2:지갑을 새로 가져왔거나 열린 지갑으로 지갑 gas 가 충분하다고 확인되면 직접 인증 버튼을 누르십시오.】',
		// 2172
		authorizeErr3:'【참고: gas 가 부족하여 지갑 인증에 실패했습니다. 지갑 관리 인터페이스로 가서 분할하거나 지갑의 gas 잔액을 확인하십시오.】',
		
		// 2176 获取余额、充值地址
		renewErr:'지불갱신지갑이 아직 생성되지 않았습니다, 고객서비스에 연락 바랍니다, 불편을 끼쳐드린 점 양해바랍니다!',
		renewTip:'고객센터로 연락해서 요금을 내고 사용하세요！',
		renewTip2:'잠시 수동 리드는 지원되지 않습니다. 불편을 끼쳐드려 죄송합니다. 비즈니스 고객센터로 연락하시기 바랍니다.',
		// renewTip3:'고객님의 계좌번호는 잠시 요금 연장 기능을 사용할 수 없습니다. 고객님께 불편을 끼쳐드릴 테니 비즈니스 고객서비스 요금 연장하시기 바랍니다.',
		// renewTip4:'고객님의 계좌번호를 수정할 수 없습니다. 고객님께 연락하여 불편을 끼쳐드린 점 양해해 주시기 바랍니다.',
		renewTip5:'패키지메뉴 수정은 지원되지 않습니다. 불편을 드려 죄송합니다. 고객님께 연락주시기 바랍니다.',
		renewTip6:'检测到您有续费中记录，钱包暂时已锁，请您稍后再试，可去充值页面查看钱包记录核对。给您带来的不便请谅解，若有问题请您联系商务客服!',

		// 服务器错误
		serverErr:'서버가 너무 많습니다. 잠시 후에 다시 시도하십시오! ',
		// 服务器错误2 请求超时
		timeout:'요청 시간이 초과되었습니다. 네트워크 환경이 정상인지 확인한 후 나중에 다시 시도하십시오!',
	},

	// 账号列表
	account:{
		title:'계정 목록 (설명:현재 각 거래소마다 하나의 계정이 제한됨)',
		create:'계정 생성',
		bind:'새 계정을 묶다',
		toCreate:'계정이 없습니다. 만들어보세요',
		newAccount:'계정을 새로 만듭니다',
		accountName:'계정 이름',
		network:'네트워크 선택',
		whiteList:'IP화이트 리스트',
		tip:'거래소의 apikey와 개인키를 얻은후 공란에 기입하지 않도록 주의하십시오',
		success:'계정 생성 성공',
		sub:'계정 목록을 통해 추가하신 계정을 확인할 수 있습니다',
		editAcc:'계정을 수정하다',
		delAcc:'계정 삭제',
		
		walletAddress:'지갑 주소',
		privateKey:'개인 키',
		apiPWD:'API암호',
		payPWD:'결제 비밀번호',
		apiName:'API이름',
	},

	// 手动操盘
	stocks:{
		tradingView:'K선 그래프',
		toolSelect:'도구 선택',
		tools:'도구',
		order:'수동으로 주문하다',
		mainOrder:'마스터 지갑 주문서',
		orderTip:'DEX 수동 주문은 주문하기 위해 메인 지갑을 호출하는 것이며, 서브 지갑은 포함되지 않습니다. 서브 지갑만 사용하여 주문하려면 DEX 도구- “DEX 배치 주문” 로 이동하여 운영하십시오',
		//DEXtool:'Dex거래도구',
		check:'매입 가격범위',
		sale:'매출 가격범위',
		priceRange:'가격범위',
		quantity:'수량',
		quantityRange:'수량 범위',
		total:'총량',
		decimal:'가격 소수점 자리',
		target:'목표 가격',
		walletsNumber:'지갑 수량',
		volume:'거래량',
		floatingRange:'플로팅 범위',
		strategy:'교역 전략',
		strategyDefault:'묵인',
		strategyCondition:'조건부 매매',
		transactionType:'거래 유형',
		floatTip0:'변동 범위는 0% 이며 대량으로 주문하는 지갑은 평균 수량으로 주문하게 됩니다',
		chain:'체인 이름',
		refresh:'잔액 새로고침 ',
		wallets:'지갑 수량',
		currency:'화폐 종류',
		totalQuantity:'총 수량',
		totalCumulative:'총누계',
		token:'라이센스 토큰token',
		walletType:'지갑 인증 형식',
		max:'최대',
		orderManagement:'주문서 관리',
		common:'보통판커브',
		advanced:'고급판커우',
		coins:'Dump필요한 화폐',
		coinsMoney:'Pump 필요한 USDT',
		investors:'개인투자자 수량',
		investorsTotal:'개인투자자 누계 ',
		myself:'내 수량',
		
		robotMange:'로봇 관리',

		buy:'사',
		sell:'팔',
		subWallet:'서브지갑',
		mainWallet:'메인 계좌 지갑',

		accountAssets:'계정 자산',
		mainAssets:'메인지갑 자산',
		subAssets:'서브지갑 자산',
		mainAssetsNFT:'메인지갑 NFT자산',
		subAssetsNFT:'서브지갑 NFT자산',

		// nft
		contractAddress:'계약 주소',
		date:'생성 시간',
		items:'총 발행량',
		owners:'보유 인수',
		totalVolume:'총 거래량',
		floor:'바닥 가격',
		average:'가격',
		currency:'거래 지원 토큰',
		more:'더 보기',
		less:'접',
		
		// 选择盘口 常用盘口
		manual:'수동 입력',
		// help
		know:'알겠습니다',
		welcomeText:[{
				title:'핸디캡 어떻게 전환합니까？',
				text:'현재 단추를 통해 거래소 쌍을 수동으로 입력할 수 있습니다.'
			},{
				title:'거래소 계좌를 만들기/보기',
				text:'현재 단추를 누르면 현재 열린 거래소 계좌를 새로 만들거나 조회할 수 있습니다, 또한 여기에는 해당 서브지갑 관리, 계정 수정, 삭제 등이 포함된다'
			},{
				title:'로봇 설정',
				text:'딜러 로봇을 클릭하면 로봇을 시작합니다. 특정 로봇을 켤 수 없을 경우에는 세트 메뉴를 구매해 해당 로봇을 켤 수 있다.'
			},{
				title:'자산 관리',
				text:'자산 보고서를 통해서 현재 거래소 계정이 대응하는 모든 자산 정보 상황을 분명하게 볼 수 있다.'
			},
		],
	},
	// 子钱包管理
	subManage:{
		totalAssets:' 서브지갑 총자산',
		useAssets:'서브지갑 사용 자산',
		notUseAssets:'자전 미사용 자산',

		wallets:'지갑 수량',
		totalAmount:'총 수량',

		mainWallet:'메인 지갑',
		address:'주소',
		chain:'체인',
		assets:'자산',
		approveTokens:'인증 목록',
		participate:'거래 권한을',
		transfer:'자금 이체',
		transferAndAuthorize:'자금이체 및 수권',
		subWalletList:'서브지갑 목록',
		enable:'활성화 여부',
		currentState:'현재 상태',
		start:'사용 하기',
		stop:'정지',
		// 创建子钱包
		createTip:'【참고: 자산과 계정 보안을 위해 지갑의 개인 키를 내보낼 수 없습니다】',
		createTip2:'【참고: 서브 지갑이 생성된 후, 메인 지갑이 로봇과 양적 도구의 거래에 관여할 필요가 없다면 제 시간에 메인 지갑의 거래 권한을 비활성화하십시오】',
		// 导入子钱包
		key:'서브지갑의 개인 키를 복사하고 붙여넣어 주세요（한 줄 건너 붙여주세요）',
		currently:'현재 입력됨',
		entry:'항목',
		importTip:'【참고: 자산과 계정 보안을 위해 저장한 개인 키를 내보낼 수 없습니다. 백업해 주십시오】',
		importTip2:'【참고: 서브지갑을 가져온 후, 메인 지갑이 로봇과 양적 도구의 거래에 관여할 필요가 없다면 제 시간에 메인 지갑의 거래 권한을 비활성화하십시오】',
		// 分拨
		distributionStrategy:'할당전략',
		strategy:'전략',
		randomAllocate:'무작위 할당',
		averageAllocate:'평균 변동 할당',
		conditionalAllocate:'조건 할당',
		floatRange:'플로팅 범위',
		walletBelow:"지갑자산'미달'",//"지갑자산'미달'...",
		floatTip1:'변동 범위가 0%，각 서브지갑에 균등하게 할당됩니다',
		floatTip2:'변동 범위 >=80%, 시스템은 무작위로 할당됩니다',
		fundsSource:'자금 출처',
		// 资金划转
		transferTip:'【참고: 안전한 자산을 위해 귀하의 계좌 주소는 주 지갑 또는 하위 지갑이어야 합니다】',
		single:'단일 지갑 전송',
		multiple:'지갑을 나누다',
		targetAddressSame:'타깃 주소가 같은 지갑이라 같은 지갑에서는 계좌이체가 불가능합니다',
		safeVer:'보안 인증',
		safeVerTip:'【참고: 현재 작업 관련 금액이 큽니다. 자금 안전을 보장하기 위해서는 메일박스 인증을 진행해야 합니다】',
		
		// 来源
		enabled:'현재 원본이 활성화되어 있습니다',
		create:'시스템 생성',
		import:'외부 가져오기',
		source:'지갑 공급',
		onlyCreate:'시스템 생성만 표시합니다',
		onlyImport:'외부 가져오기만 보이기',
		allOpen:'단추로 열기',
		allClose:'단추에 닫기',
		allOpenTip:'현재 소스의 지갑을 모두 열겠습니까?',
		allCloseTip:'현재 소스의 지갑을 모두 닫습니까?',
		
		// 交易限制
		transactionRestrictionModify:'거래제한수정',
		transactionRestriction:'거래 제한',
		unlimited:'무제한',
		buyOnly:'만 사',
		sellOnly:'만 판',
		allUnlimitedTip:"현재 선택한 지갑 주소의 거래 제한을'무제한'으로 변경하시겠습니까?",
		allBuyOnlyTip:"현재 선택한 지갑 주소의 거래 제한을'구매만'으로 수정하시겠습니까?",
		allSellOnlyTip:"현재 선택한 지갑 주소의 거래 제한을'판매만'으로 변경하시겠습니까?",
		limitTip:'交易限制：无限制（钱包参与下单买或者卖随机）、只买（钱包只能参与下单买入）、只卖（钱包只能参与下单卖出）',

		// NFT
		openBtnTip:'클릭하면 nft 자산이 목록 아래에 나타난다',
		openBtn:'전시',
		closeBtn:'접',
		copyAddress:'주소 복사',
	},

	robot:{
		list:[
			// 1
			{
				name:'고 투하 저 흡입 ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 상황:</span> 요동치는 장세에서 지속적으로 싸게 사고 비싸게 판다.이와 동시에 가격을 관리, 통제하여 가격이 너무 높거나 너무 낮은것을 방지할수 있습니다. 매도 및 매입 가격대를 설정할 수 있으며 해당 범위를 초과하는 주문이 실행되면 로봇은 해당 매매를 실행합니다.실행 속도와 개수는 인자의 설정에 따라 결정되며, 개수는 설정값의 상하 50% 이내의 무작위 값입니다。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">특수 매개변수 설명：</span>
					<br/><br/>경쟁자 수:만약"경쟁자 수"를 선택하면,"수량"란이 작동하지 않으며, 로봇은 현재 구매 1건 또는 판매 1건을 기준으로 주문을 합니다.
					<br/><br/>최대 포지션:현재 최대 포지션을 관리하는 데 사용됩니다. 로봇은 귀하의 계좌의 잔고가 최대 포지션보다 큰 것이 발견되면 매입을 중지합니다.
					<br/><br/>최소 포지션:현재 보유 중인 화폐의 최소 양을 관리하는 데 사용됩니다. 로봇은 귀하의 계정 잔고가 최소 포지션보다 작거나 같은 것이 감지되면 매도 작업을 중지합니다.
					<br/><br/>작업 조건:이 옵션을 체크하면 디스크가 끼여서 쌍타크가 정상적으로 작동하지 않을 때만 디스크를 구입하는 주문을 실행합니다.이 옵션을 체크하지 않으면 가격이 설정된 값에 도달하면 즉시 실행됩니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동 및 정지:</span> 시동 단추를 누르면 로봇은 작업 큐에 들어가 10분 이내에 작업을 시작합니다.정지 단추를 누르면 로봇은 즉시 정지됩니다</p>
				`,
			},
			// 2
			{
				name:'배치 판매(Batch Selling)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span> 많은 양의 토큰을 팔게 됩니다。</p>
				<br/><p style="color: #9198AA;">
				이 로봇은 설정된 최저 판매 가격, 매도 수량과 작업 시간 (초 간격)에 따라 통화 매각을 진행한다.각 볼륨과 볼륨을 설정할 수 있습니다.이 두 볼륨을 곱한 결과는 해당 작업에서 판매할 로봇의 총량입니다. 숨겨진 배송을 보장하기 위해 실제 주문 볼륨은 설정값 아래 30%의 범위에서 임의의 값을 가집니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동과 정지:</span> 시동 단추를 누르면 로봇은 즉시 작동을 시작합니다.설정된 목표를 달성하거나 부족하면 로봇은 작업을 종료합니다. </p>
				`,
			},
			// 3
			{
				name:'차익 거래(Arbitrage)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span> 거래소간 가격 차이.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라메터 설명：</span>
					<br/><br/> 타겟플랫폼:현재 거래소가 필요로 하는 거래소를 선택합니다.
					<br/><br/> 현재 플랫폼 수속비:단위는% 이고, 예를 들어 수속비 1000분의 1을 0.1기입합니다.
					<br/><br/> 타겟플랫폼 수수료:단위는% 이고, 예를 들어 수수료 1000분의 1을 0.1기입합니다.
					<br/><br/> 가격소수점자리:가격소수점의 자리수만큼 씁니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동과 정지:</span> 시동 단추를 누르면 로봇은 즉시 작동을 시작합니다.정지 버튼을 누르면 로봇이 정지됩니다 </p>
				`,
			},
			// 4
			{
				name:'마틴(Martingale)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span> 구성된 모든 수준에서, Martingale은 표시된 배수에 따라 볼륨의 위치를 높이고 평균 가격을 재진입할 것입니다.</p >
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라메터 설명：</span>
					<br/><br/>첫 번째 위치 크기: 첫 번째 주문의 시작 금액,단위는{0}
					<br/><br/>최대 포지션 추가 차수: 포지션을 연 후, 포지션을 늘리는 총 횟수(공개 포지션 제외)
					<br/><br/>이익 타크네 비율:단위는%, 최신 가격이 현재 평균 포지션 가격보다 높다는 것은 비율입니다. 이 가치에 도달하면, 이익은 즉시 받아들여지지 않을 것이며, 이익은 가격이 일정 비율로 콜백될 때만 이루어질 것입니다.
                                   <br/> 이익 콜백 비율: 이익 비율에 도달한 후 포지션을 판매하기 위한 가격 콜백은 얼마입니까?
                                   <br/><br/> 포지션 추가 가격 스프레드:단위는%, 지난번 매입가격부터 계산하면 시세가 계속 하락하여 얼마만큼 추가 포지션 준비를하고,이 수치가 되면 바로 추가 포지션 하지는 않으며, 가격이 일정 비율 반등해야만 매입할 수 있습니다.
                                   <br/> 위치 추가 콜백 비율:단위는%, 증가 포지션 비율에 도달한 후, 포지션을 늘리기 전에 가격이 얼마나 반등하는지.
                                   <br/><br/> 첫 번째 주문 두 배: 첫 번째 주문을 구매한 후, 가격이 1% 이내에 변동하면 추가 구매가 이루어집니다. 금액은 첫 번째 주문 금액과 같으며 포지션 추가 수에 포함되지 않습니다.
					<br/><br/>위치 추가 배수: 각 포지션 추가 금액이 이전 포지션의 몇 배입니까?
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동과 정지:</span> 로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈출 것입니다. 일단 중단되면, 보류 중인 모든 주문은 취소될 것입니다. </p>
				`,
			},
			// 5
			{
				name:'그리드(Grid)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span> 구성된 모든 수준에서 그리드는 구성된 금액에 따라 볼륨의 위치를 높이고 평균 가격 인하를 재입력합니다.</p >
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라메터 설명：</span>
					<br/><br/>첫 번째 포지션 크기: 첫 구매 주문 금액.
					<br/><br/>그리드 수: 최대 구매 수 (처음 포함)
					<br/><br/>이익 비율: 단위는%, 매회 매입 후 간격 얼마나 손익분기점 매깁니다.
					<br/><br/>위치 추가 간격(PAI): 단위는 %. 구매 후 다음 구매 주문을 하기 위한 가격 간격.
					<br/><br/>PAI의 마진 간격 증가: PAl의 그라디언트, 채워진 숫자가 X보다 크면, 다음 마진 간격은 X배 확대됩니다.
					<br/><br/>위치 추가 금액의 마진 간격 증가: 각 포지션 추가 주문에 배치된 금액의 그라디언트. 채워진 숫자가 Y보다 크면, 다음 위치 추가 금액에 Y배를 곱합니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동과 정지:</span> 로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈출 것입니다. 일단 중단되면, 보류 중인 모든 주문은 취소될 것입니다.</p>
				`,
			},
			// 6
			{
				name:'볼륨 생성(Volume Generating) ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span>거래량을 창출하고 시장 활동을 증가시킨다.</p>
				<br/><p style="color: #9198AA;">
					주문은 명시된 각 범위 내에서 무작위 주파수와 볼륨으로 실행됩니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시동과 정지:</span> 로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈출 것입니다. 시장이 통합 단계에 접어들면, 로봇은 활동을 일시 중지할 것입니다.</p >
				`,
			},
			// 7
			{
				name:'주문서 관리(Orderbook Management) ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오:</span> 거래량을 창출하고 시장 활동을 증가시킵니다.</p >
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>구매 주문량: 각 구매 주문의 거래량. 실제 볼륨은 명시된 볼륨의 30% 편차 내에 있을 것입니다.
					<br/><br/>판매 주문량: 각 판매 주문의 수량. 실제 볼륨은 명시된 볼륨의 30% 편차 내에 있을 것입니다.
					<br/><br/>스프레드 크기: 최고의 입찰가와 최고의 요청의 백분율 차이.
					<br/><br/>레벨당 스프레드: 각 레벨 간의 가격 변동 비율.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도. 만약 6자리 소수점이라면 6을 기입하세요.
					<br/><br/>주문 수: 가격대를 지원하는 데 사용된 총 주문 수. 기본값은 20입니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지: </span> 로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈출 것입니다.</p>
				`
			},
			// 8
			{
				name:'지원 구역(Support Zone) ' ,
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>표시된 가격대에서 지원 구역을 만듭니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격범위:지지가격과 압력을 설정해야 하는 가격범위를 기재한다
					<br/><br/>총 금액: 명시된 가격대의 지원 가격을 위한 총 금액.
					<br/><br/>주문 수: 가격을 지원하기 위해 실행할 총 주문 수.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>로봇은 클릭한 각 버튼에 따라 즉시 시작하고 중지하거나 모든 주문을 이행하면 중지합니다.</p>
				`
			},
			// 9
			{
				name:'저항 구역(Resistance Zone )',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>표시된 가격대에 저항 구역을 만듭니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격 범위: 저항할 가격 범위.
					<br/><br/>총 금액: 명시된 가격대에서 가격에 저항하기 위한 총 금액.
					<br/><br/>주문 수: 가격에 저항하기 위해 실행할 총 주문 수.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈추거나 모든 주문을 이행하면 중지합니다.</p>
				`
			},
			// 10
			{
				name:'추세 설정(Trend Setting )',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>플레이트를 늘리거나 치는 데 사용되는 추세 설정, 플레이트는 설정된 가격에 따라, 로봇은 매번 5 ~ 10분 동안 작업 시간 간격 후 "시작 가격"의 위치로 가격을 즉시 푸시하기 시작하고, 컷오프 시간을 설정하면 목표 가격에 도달합니다. 소매 간섭이 있는 경우, 로봇은 자동으로 주행 속도를 높이거나 늦춥니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
                    <br/><br/>총 금액: 추세를 시행하는 데 전념하는 총 금액.
					<br/><br/>종료 시간: 로봇이 목표 가격에 도달하는 시간을 설정한다
					<br/><br/>시작 가격: 메커니즘을 시작하기 위한 첫 번째 주문의 가격.
                    <br/><br/>목표 가격: 자산에 대한 원하는 가격.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지: </span>로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈추거나 목표 가격이 충족되면 멈춥니다.</p>
				`
			},
			// 11 成交放量机器人
			{
				name:'엄청난 거래량',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>정해진 시간 내에, 로봇은 시장의 판매 측면에서 대량 주문을 하고 빠르게 재구매할 것입니다. 이 기능은 짧은 시간에 상당한 거래량을 생성하는 데 사용됩니다. 한편, 그것은 또한 특정 가격대의 지원과 판매 의도에 대한 소매 투자자들의 관심을 이끕니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>거래량:1분 동안 로봇이 해야 하는 거래량
					<br/><br/>작업시간:몇분
					<br/><br/>단서 예산:사용자와의 거래로 인한 자금 소비 예산이다
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},
			{
				name:'차트(Charting)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>원하는 추세를 개발하기 위해 가격을 올리거나 낮추세요. 보류 중인 주문은 주문의 빈도와 가격에 따라 이루어질 것입니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>상한: 차트에 허용되는 가장 높은 가격.
					<br/><br/>낮은 한도: 차트에 허용되는 최저 가격.
					<br/><br/>스프레드 크기: 최고의 입찰가와 최고의 요청의 백분율 차이.
					<br/><br/>레벨당 스프레드: 각 트랜치 간의 가격 변동 비율.
					<br/><br/>구매 주문 수량: 각 트랜치에서 유지해야 할 구매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
					<br/><br/>판매 주문 수량: 각 트랜치에서 유지해야 할 판매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
					<br/><br/>주문당 최대 수량: 각 주문이 충족할 최대 수량.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
					<br/><br/>총 일일 주문 수: 하루에 허용되는 최대 주문 수.
					<br/><br/>인덱스 팔로우: 이 기능이 활성화되면, 차트 로봇은 주요 시장(BTC, ETH 등)의 추세를 참조하여 작동합니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">위험 관리：</span>다른 사용자가 거래에 참여하여 가격이 설정된 범위를 벗어나면 로봇이 자동으로 작동을 멈춥니다.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">특별 설명：</span>로봇이 실행 중에 사용자 계정에서 대량 주문을 받으면, 로봇은 사용자가 설정한 저항 구역으로 식별하고 이러한 주문과 거래하지 않습니다. 대량 주문의 정의: (주문 금액 > 구매 및 판매 주문의 정해진 수량의 5배)</p>
				`
			},{
				name:'시장 동기화 (알트코인)' ,
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>다른 거래소에서 토큰의 시장 움직임을 모방한다. 이 모조는 볼륨 생성 및 주문서 관리 기능을 포함한다. 따라서, 시장 동기화가 활성화되면, 동일한 토큰에서 작동하는 이 두 로봇은 멈출 것입니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>모드: 표준 모드에서는 로봇이 목표 디스크 가격에 따라 추적합니다. 헤지 모드에서 로봇은 크로스 헤징을 통해 가격과 유동성의 동기화를 동시에 실현하여 교차 차익거래를 효과적으로 방지할 수 있습니다.
					<br/><br/>대상 거래소: 쌍이 Market Sync에 나열된 거래소.
					<br/><br/>스프레드 크기: 최고의 입찰가와 최고의 요청의 백분율 차이.
					<br/><br/>레벨당 스프레드: 각 트랜치 간의 가격 변동 비율.
					<br/><br/>구매 주문 수량: 각 트랜치에서 유지해야 할 구매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
					<br/><br/>판매 주문 수량: 각 트랜치에서 유지해야 할 판매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
					<br/><br/>최대 볼륨: 주문당 허용되는 최대 볼륨.
					<br/><br/>가격 정밀도: 가격의 소수 정밀도.만약 6자리 소수점이라면 6을 기입하세요.
					<br/><br/>총 일일 주문 수: 하루에 허용되는 최대 주문 수.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>로봇은 클릭한 각 버튼에 따라 즉시 시작하고 멈출 것입니다.</p>
				`
			},{
				name:'목표 가격(Target Price) ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>자산이 원하는 가격을 달성하도록 돕니다. 이 목표는 매일 밤 12시 이전에 충족될 것이며 그 후에도 범위 진동이 계속될 것입니다. 볼륨 생성 로봇은 현실적인 추세를 달성하기 위해 동시에 사용해야 합니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>금액: 목표 가격의 최대 예산, 소비가 예산을 초과하면 로봇이 자동으로 멈춥니다.
					<br/><br/>목표 가격: 0:00에, 로봇이 도달할 목표 가격
					<br/><br/>스프레드 크기: 첫 구매 주문과 첫 판매 주문 사이를 유지하는 데 필요한 스프레드 백분율. (여기서는 백분율 대신 절대값을 채워야 합니다)
					<br/><br/>트랜치당 주문 (입찰 요청): 트랜치당 주문 수량. 이 로봇의 이 기능은 사용자 지정을 지원하며, 소형, 중형 및 대형 주문인 보류 중인 주문의 세 가지 스케일이 있습니다.
					<br/><br/>레벨당 스프레드: 구매 및 판매 주문의 각 트랜치 사이를 유지하는 데 필요한 가격 스프레드의 절대 가치.
					<br/><br/>레벨당 스프레드: 구매 및 판매 주문의 각 트랜치 사이를 유지하는 데 필요한 가격 스프레드의 절대 가치.
					<br/><br/>가격 정밀도: 가격의 소수점 이하 자릿수를 채우세요.만약 6자리 소수점이라면 6을 기입하세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'시장 동기화 (주요 동전) ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>그것은 현재 토큰이 다른 거래소의 같은 이름의 토큰의 가격에 따라 변동할 수 있게 해준다. 일치하는 주문 및 주문 관리 기능이 포함되어 있기 때문입니다. 활성화되면, 동일한 거래 쌍에서 볼륨 생성 로봇과 주문서 관리 로봇이 자동으로 종료됩니다. 그것은 자체 크로스 플랫폼 헤지 기능을 가지고 있다. 근무 조건: 시장에는 깊이가 있거나 적어도 하나의 보류 중인 주문이 있어야 합니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>대상 거래소: 쌍이 Market Sync에 나열된 거래소.
					<br/><br/>스프레드 크기: 최고의 입찰가와 최고의 요청의 백분율 차이.
					<br/><br/>레벨당 스프레드: 각 트랜치 간의 가격 변동 비율.
					<br/><br/>구매 주문 수량: 각 트랜치에서 유지해야 할 구매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
					<br/><br/>판매 주문 수량: 각 트랜치에서 유지해야 할 판매 주문 수. 실제 크기는 표시된 수량에서 30% 범위 내에서 무작위화됩니다.
                                    <br/><br/>최대 볼륨: 주문당 허용되는 최대 볼륨.
					<br/><br/>가격 정밀도: 가격의 소수점 이하 자릿수를 채우세요.만약 6자리 소수점이라면 6을 기입하세요.
					<br/><br/>총 일일 주문 수: 하루에 허용되는 최대 주문 수.
					<br/><br/>최대 거래량: 허용되는 최대 거래량.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'DEX 차트(Charting)',//DEX차트(Charting)
				//  최대 600초입니다
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>그것은 정해진 가격대에서 옆으로 만들고 거래량을 만드는 데 사용된다. 게다가, 시장의 활동을 늘리고 DEX의 촛대 차트의 추세를 유지하세요.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격 범위: 라인 유지 보수 범위를 제어하기 위해 낮은 것부터 높음까지 숫자를 채우세요. 세트 범위를 초과하면, 로봇은 세트 범위 내에서 실행하기 위해 최선을 다할 것입니다.
					<br/><br/>거래당 거래량: 낮은 것에서 높은 숫자로 숫자를 채우고, 각 거래의 거래량은 무작위 범위이며, 거래 기록이 실제 사용자 거래처럼 보이도록 범위를 더 큰 범위로 설정할 수 있습니다.
					<br/><br/>시간 간격: 각 거래의 시간 간격과. 덱스 서비스 요금과 가스 요금의 높은 비용으로 인해, 최소 30초를 채우는 것이 좋습니다.
					<br/><br/>차트 로봇이 작동할 때, 그것은 기본적으로 전체 시장의 가격 추세 방향을 가리킬 것입니다. 이 매개 변수는 가격 추세의 방향을 더욱 조정할 수 있습니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 어떤 이유로든 거래가 실패하거나 구매와 판매 주문의 가격 차이가 매우 작아서 시장이 막히면, 로봇은 작업을 일시 중지할 것입니다.</p>
				`
			},{
				name:'DEX 시장 동기화 (AltCoins) ',
				// , 최대값은 600이다
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>그것은 가격을 제어하기 위해 DEX에서 CEX의 가격을 따르는 데 사용됩니다. 따르는 거래 쌍은 모든 거래 쌍이 될 수 있습니다. 가격이 예상 범위 내에서 작동하는지 확인하기 위해 가격 확장 매개 변수를 제어하십시오.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>목표 거래소: 따라야 할 목표 거래 쌍의 교환.
					<br/><br/>대상 기호: 따라야 할 대상 기호.
					<br/><br/>가격 확장: 다음 거래 쌍의 가격 움직임을 확장합니다. 매개 변수는 0보다 큰 숫자를 지원합니다. 1%를 채우면, 현재 토큰이 뒤따르는 가격은 목표 거래 쌍 가격의 1%가 될 것입니다. 현재 토큰이 목표 거래 쌍 가격으로 정확히 실행되기를 원한다면, 100%로 설정하십시오.
					<br/><br/>시간 간격: 가격을 따르기 위한 각 실행 사이의 시간 간격. 단위는 초이고. DEX의 서비스 수수료와 가스 요금이 높기 때문에 30초 이상 권장됩니다. 고주파 거래에는 적합하지 않습니다.
					<br/><br/>주문당 최대 거래량: 가격을 따르는 각 실행의 최대 한도. 이 볼륨을 초과하면, 가격을 따르는 것이 너무 많은 토큰을 소비해야 한다는 것을 의미합니다. 로봇은 작업을 종료하고 로그에 오류를 보고할 것입니다. 이 매개 변수는 예상치 못한 상황에서 자산을 보호하는 데 사용됩니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'DEX 범위 거래 ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사이드웨이 시장에서 지속적으로 낮게 사고 높게 팔곤 했다. 동시에, 가격이 너무 높거나 너무 낮지 않도록 가격을 통제할 수 있으며, 옆으로 반복적으로 차익거래할 수 있습니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격이 높을 때: 가격이 정가보다 높으면 로봇이 팔릴 것입니다.
					<br/><br/>가격이 낮을 때: 가격이 정가보다 낮을 때, 로봇이 살 것입니다.
					<br/><br/>시간 간격: 이 로봇이 한 번 작동하는 빈도를 결정합니다. 빈도가 높으면 거래 비용이 더 높고 가격 통제가 더 엄격해질 것입니다. 요구 사항에 따라 구성하십시오. 30초 이상마다 작업하는 것이 좋습니다.
					<br/><br/>주문당 수량: 정해진 가격이 트리거된 후 각 주문의 수량. 거래 기록을 정상적으로 보이게 하기 위해, 실제 주문량은 설정된 볼륨 위와 낮은 50% 범위 내의 무작위 값이 될 것입니다.
					<br/><br/>최대 위치: 자산 보안 매개 변수. 로봇이 토큰의 균형이 설정된 최대 위치보다 크거나 같다는 것을 감지하면, 로봇은 구매를 중단할 것입니다.
					<br/><br/>최소 위치: 자산 보안 매개 변수. 로봇이 토큰의 균형이 설정된 최소 위치보다 낮거나 같다는 것을 감지하면, 로봇은 판매를 중단할 것입니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'DEX 스냅업(Snap Up) ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>지정된 기호에 대한 대량 주문 구매를 모니터링하는 데 사용됩니다. 로봇이 대량 구매 주문을 감지했을 때, 차익거래를 완료하기 위해 대량 주문 전에 더 높은 가스 요금을 거래할 것입니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>트리거 볼륨: 로봇이 세트 볼륨을 초과하는 구매 주문이 나타났지만 닫히지 않았다는 것을 발견하면 구매 작업을 실행합니다.
					<br/><br/>매번 구매: 구매 작업이 트리거될 때 지정된 구매 금액.
					<br/><br/>자동 판매 여부: 이 옵션이 활성화되면, 로봇은 주문 거래 후 가격이 상승하면 즉시 판매됩니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'DEX 새로운 토큰 스나이퍼 ',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>DEX에서 새로 생성된 기호의 경우, 초기 가격은 항상 매우 낮다. 그래서 일어날 수 있는 좋은 기회가 있습니다. 이 로봇은 대상 기호의 생성을 모니터링하고 생성되자마자 구매할 수 있습니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>구매할 볼륨 : 대상 기호가 생성된 후 구매할 볼륨.
					<br/><br/>자동 판매 여부: 이 옵션이 활성화되면, 로봇은 주문 거래 후 가격이 상승하면 즉시 판매됩니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},{
				name:'DEX 에서 CEX 로의 차익거래 ',//DEX에서 CEX로의 차익거래 
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사용자가 DEX에서 CEX로 차익거래할 수 있도록 도와주세요.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>대상 교환: 현재 거래소와 차익거래할 교환을 선택하세요.
					<br/><br/>현재 서비스 요금: 단위는 %입니다. 현재 서비스 요금이 0.1%라면 0.1로 채우세요.
					<br/><br/>목표 서비스 요금: 단위는 %입니다. 목표 서비스 요금이 0.1%라면 0.1로 채우세요.
					<br/><br/>가격 소수점 문자: 가격의 소수점 숫자를 채우세요.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
			},

			{
				name:'NFT 차트(Charting)',
				//  최대 600초입니다
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>그것은 정해진 가격대에서 옆으로 만들고 거래량을 만드는 데 사용된다. 게다가, 시장의 활동을 늘리고 DEX의 촛대 차트의 추세를 유지하세요.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격 범위: 라인 유지 보수 범위를 제어하기 위해 낮은 것부터 높음까지 숫자를 채우세요. 세트 범위를 초과하면, 로봇은 세트 범위 내에서 실행하기 위해 최선을 다할 것입니다.
                                     <br/><br/>거래당 볼륨: 낮은 것부터 높은 것까지 숫자를 채우고, 각 거래의 거래량은 무작위 범위가 되며, 거래 기록이 실제 사용자 거래처럼 보이도록 범위를 더 큰 범위로 설정할 수 있습니다.
                                   <br/><br/>시간 간격: 각 거래의 시간 간격과. 덱스 서비스 요금과 가스 요금의 높은 비용으로 인해, 최소 30초를 채우는 것이 좋습니다.
                                   <br/><br/>트렌드 방향: 차트 로봇이 작동할 때, 기본적으로 전체 시장의 가격 추세 방향을 나타냅니다. 이 매개 변수는 가격 추세의 방향을 더욱 조정할 수 있습니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/중지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 어떤 이유로든 거래가 실패하거나 구매와 판매 주문의 가격 차이가 매우 작아서 시장이 막히면, 로봇은 작업을 일시 중지할 것입니다.</p>
				`
			},{
				name:'NFT 범위 거래(Range Trading) 로봇',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사이드웨이 시장에서 지속적으로 낮게 사고 높게 팔곤 했다. 동시에, 가격이 너무 높거나 너무 낮지 않도록 가격을 통제할 수 있으며, 옆으로 반복적으로 차익거래할 수 있습니다.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">파라미터 설명：</span>
					<br/><br/>가격이 높을 때: 가격이 정가보다 높으면 로봇이 팔릴 것입니다.
					<br/><br/>가격이 낮을 때: 가격이 정가보다 낮을 때, 로봇이 살 것입니다.
					<br/><br/>시간 간격: 이 로봇이 한 번 작동하는 빈도를 결정합니다. 빈도가 높으면 거래 비용이 더 높고 가격 통제가 더 엄격해질 것입니다. 요구 사항에 따라 구성하십시오. 30초 이상마다 작업하는 것이 좋습니다.
					<br/><br/>주문당 수량: 정해진 가격이 트리거된 후 각 주문의 수량. 거래 기록을 정상적으로 보이게 하기 위해, 실제 주문량은 설정된 볼륨 위와 낮은 50% 범위 내의 무작위 값이 될 것입니다.
					<br/><br/>최대 위치: 자산 보안 매개 변수. 로봇이 토큰의 균형이 설정된 최대 위치보다 크거나 같다는 것을 감지하면, 로봇은 구매를 중단할 것입니다.
					<br/><br/>최소 위치: 자산 보안 매개 변수. 로봇이 토큰의 균형이 설정된 최소 위치보다 낮거나 같다는 것을 감지하면, 로봇은 판매를 중단할 것입니다.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>시작 버튼을 클릭하면 로봇이 즉시 작동하기 시작합니다. 정지 버튼을 클릭하면 로봇이 즉시 멈춥니다.</p>
				`
		},],
		showRunning:'실행만 표시',
		addrobot:'로봇 추가',
		log:'로봇 로그',
		// 1
		higherthan:'위의 가격',
		sellout:'팔다',
		below:'아래 가격',
		purchase:'사다',
		interval:'주문 간격',
		second:'초',
		per:'주문 수량',
		opponents:'상대 수량',
		maximum:'최대포지션',
		minimum:'최소포지션',
		working:'작동 조건',
		chuck:'주문서가 멈춘 경우에만 활성화하세요',
		// 2
		floorPrice:'최저 가격',
		interval2:'간격',
		quantityPerTime:'매번 수량',
		frequency:'레벨 수',
		// 3
		targetExchange:'대상 거래소',
		currentCharge:'현재 서비스 요금',
		targetCharge:'대상 서비스 요금',
		unit:'단위는 %입니다',
		priceDecimal:'가격 정밀도',
		quantityDecimal:'수량 정밀도',
		// 4
		openingAmount:'첫 입고금액',
		repTimes:'추가매입차수',
		interferenceRange:'손익정지 폭',
		repDifferences:'추가 포지션 스프레드',
		ci:'차/번',
		
		stopBackRatio:'이익정지조정비율',
		addBackRatio:'추가매입조정비율',
		firstDouble:'첫 주문 두 배',
		addMultiple:'입고배수',

		firstAmount:'첫 입고금액',
		// addNum:'추가매입차수',
		stopRatio:'이익 비율',
		// declineRatio:'가격이 하락할 때 포지션 추가',
		// 5
		priceRange:'가격범위',
		grids:'격자 수',
		perGear:'트랜치당 수량',
		maintain:'구매 주문 유지',
		stopPrice:'정지 가격',
		more:'다수확',
		short:'공매',

		addInterval:'추가 간격',
		addIntervalIncrease:'풀링 간격 증가',
		addAmountIncrease:'추가매입금액 증가',
		// 6
		counterQuantity:'부피 범위',
		perMinute:'1분당',
		// 7
		orderPerNum:'구매 주문량',
		sellPerNum:'판매 주문량',
		difference:'스프레드 크기',
		perDifference:'레벨당 스프레드',
		maintenance:'주문 수',
		// 8 9
		protective:'총 금액',
		gear:'주문 수',
		// 10
		buyBtn:'매입',
		sellBtn:'매출',
		plan:'총 금액',
		// intervalRange:'간격 범위(초)',
		random:'무작위',
		// perInterval:'가격 변동',
		endTime:'종료 시간',
		startPrice:'시작 가격',
		targetPrice:'목표 가격',
		// 11
		tradingVolume:'거래량',
		workingHours:'작업시간',
		splitBudget:'단서 예산',
		minute:'분',
		minutes:'분',
		// 12
		upperLimit:'상한',
		lowerLimit:'하한',
		buy:'구매 주문 수량',
		offers:'판매 주문 수량',
		singleMax:'주문당 최대 수량',
		swiping:'일일 주문 총액',
		individual:'개',
		indexFollow:'인덱스 팔로우',
		// 13
		standard:'표준 모드',
		rebalance:'헤지 모드',
		targetDisk:'대상 교환',
		// 14
		budget:'예산 소모',
		target:'목표 가격',
		paySmall:'레벨당 구매 주문 (작은))',
		sellSmall:'레벨당 판매 주문 (작은)',
		gears:'레벨 수',
		payMedium:'레벨당 구매 주문 (중간)',
		sellMedium:'레벨당 판매 주문 (중간)',
		payLarge:'레벨당 구매 주문 (대형)',
		sellLarge:'레벨당 주문 판매 (대형)',
		// 15
		hop:'최대 거래량',
		// 16
		transaction:'거래당 거래량',
		intervalTime:'간격 시간',
		direction:'추세 방향',
		oscillation:'무작위 진동',
		rise:'상승',
		fall:'하락',
		// 17
		targetPair:'대상 쌍',
		scaling:'가격 조정',
		transactionMax:'주문당 최대 거래량',
		// 19
		trigger:'볼륨 트리거',
		everyPurchase:'매번 매입',
		autoSell:'자동으로 판매할지 여부',
		isAutoSell:'자동 매출',
		// 20
		contract:'목표 계약',
		orderAmount:'주문 금액',
		multiple:'수익 복수',
		openingTime:'업무 개시 시간',
		deduction:'세금 공제 여부',
		times:'배수',
		taxDeduction:'세금 공제',
		noTaxDeduction:'세금 공제 없음',

		
		success:'로봇이 성공적으로 추가되었습니다',
		sub:'추가된 로봇은 거래 로봇에서 볼 수 있습니다',
		parameter:'매개 변수 설정',
		current:'현재 상태',
		running:'로봇 작동',
		stopped:'로봇이 종료되었습니다',
		robotEmpty:'추가된 로봇이 없습니다, 추가하러 가세요',
		robotEmpty2:'현재 로봇이 활성화되지 않았습니다',
	},

	// 工具
	tools:{
		check:{
			name:'구매 주문을 일괄적으로 배치합니다',
			sub:'사용자가 특정 가격대 사이에서 주문할 수 있도록 지원합니다.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사용자가 특정 가격대 사이에서 주문할 수 있도록 지원합니다.</p>
				<br/>
				<p style="color: #9198AA;">정해진 가격대와 총 주문 수에 따라, 이 로봇은 정가 범위를 여러 계층으로 나눈 다음(수량은 총 주문 수와 같다), 각 계층 가격으로 주문합니다. 각 주문의 특정 수량은 보류 중인 수량 범위 내의 무작위 수량입니다.
"가격 범위"는 특정 가격을 채워야 한다. 먼저 하한을 채우고, 상한을 채우세요.
"볼륨"은 각 구매 또는 판매 주문의 볼륨의 무작위 범위를 결정합니다.
"총량"은 로봇이 한 번에 보류 중인 주문 수를 나타냅니다.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>매개 변수가 채워진 후, "제출" 버튼을 클릭하면 로봇이 즉시 작동하고 주문이 완료되면 중지됩니다.</p> 
			`,
		},
		sale:{
			name:'판매 주문을 일괄적으로 배치하세요',
			sub:'사용자가 특정 가격대 사이에서 주문할 수 있도록 지원합니다.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사용자가 특정 가격대 사이에서 주문할 수 있도록 지원합니다.</p>
				<br/>
				<p style="color: #9198AA;">정해진 가격대와 총 주문 수에 따라, 이 로봇은 정가 범위를 여러 계층으로 나눈 다음(수량은 총 주문 수와 같다), 각 계층 가격으로 주문합니다.각 주문의 특정 수량은 보류 중인 수량 범위 내의 무작위 수량입니다.
"가격 범위"는 특정 가격을 채워야 한다. 먼저 하한을 채우고, 상한을 채우세요.
"볼륨"은 각 구매 또는 판매 주문의 볼륨의 무작위 범위를 결정합니다.
"총량"은 로봇이 한 번에 보류 중인 주문 수를 나타냅니다.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>매개 변수가 채워진 후, "제출" 버튼을 클릭하면 로봇이 즉시 작동하고 주문이 완료되면 중지됩니다.</p> 
			`,
		},
		revoke:{
			name:'일괄 주문 취소',
			sub:'사용자가 특정 가격대 사이의 모든 주문을 취소할 수 있도록 지원합니다.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사용자가 특정 가격대 사이의 모든 주문을 취소할 수 있도록 지원합니다.</p>
				<br/>
				<p style="color: #9198AA;">정해진 가격대에 따라 특정 범위 내에서 주문 대기 중인 모든 사용자를 취소하십시오.
"가격 범위"는 특정 가격을 채워야 한다. 먼저 범위의 하한을 채운 다음 상한을 채우십시오.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>매개 변수가 채워진 후, "제출" 버튼을 클릭하면 로봇이 즉시 작동하고 주문 취소가 완료되면 중지됩니다.</p> 
			`,
		},
		calculate:{
			name:'DEX 거래량 측정',
			sub:'가격이 지정된 가격으로 상승하거나 하락하는 데 필요한 양을 계산합니다. 또는 가격이 어디로 이동할지 알기 위해 볼륨을 입력하세요.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>가격이 지정된 가격으로 상승하거나 하락하는 데 필요한 양을 계산합니다. 또는 가격이 어디로 이동할지 알기 위해 볼륨을 입력하세요.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>목표 가격: 예상 목표 가격을 입력하면 도구가 필요한 양을 계산합니다. 거래량 매개 변수는 비워 둘 수 있습니다.
                            <br/>거래량이 필요합니다: 예상 볼륨을 입력하면 도구가 가격이 어디로 이동할지 계산합니다. 가격 매개 변수는 비워 둘 수 있습니다.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">시작/정지：</span>매개 변수가 채워진 후, "제출" 버튼을 클릭하면 로봇이 즉시 작동하고 계산이 완료되면 중지됩니다.</p> 
			`,
		},
		transaction:{
			name:'DEX 배치 주문',
			sub:'대량 구매 및 판매 작업을 위해 DEX의 모든 서브 지갑을 사용하세요',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>대량 구매 및 판매 작업을 위해 DEX의 모든 서브 지갑을 사용하세요.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>지갑 수량: 주문할 지갑 수를 입력하십시오.
				<br/>거래량: 총 거래량을 입력하십시오. 기호 A-B의 경우, 이 매개 변수는 구매할 때 B가 얼마나 사용될지, 그리고 판매할 때 A가 얼마나 팔릴지 나타냅니다.
				<br/>플로팅 범위: 플로팅 비율에 따라 각 지갑에 주문량을 조절할 수 있습니다.주문 수량 범위: 【(주문 총량/주문 지갑 수)* 최소 유동 비율 ~ (주문 총량/주문 지갑 수)* 최대 유동 비율】, 예를 들어 유동 비율 40%, 주문 수량 범위: 【(주문 총량/주문 지갑 수)*60% ~ (주문 총량/주문 지갑 수)*140%】.
                <br/>거래 유형: 구매 또는 판매.</p>
			`,
		},
		balance:{
			name:'DEX 서브 지갑 잔액 보기',
			sub:'모든 서브 지갑의 자산 잔액 정보를 계산하고 서브 지갑 잔액을 수동으로 새로 고칠 수 있습니다.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>사용자가 모든 서브 지갑의 자산 잔액 정보를 계산할 수 있도록 돕고, 잔액을 얻는 동안 문제가 있을 때 서브 지갑 잔액을 수동으로 새로 고칠 수 있도록 도와주세요.</p>
			`,
		},
		wallet:{
			// name:'DEX 서브 지갑 생성 도구',
			name:'서브 지갑 생성',
			sub:'배치로 주문하기 위한 서브 지갑 만들기',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>배치로 주문하기 위한 서브 지갑을 만드세요.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>체인 이름: BSC, MATIC, OEC, HECO 등과 같은 지갑이 만들어지는 체인
				<br/>서브 지갑 수: 만들 서브 지갑 수를 나타내는 숫자를 채우세요.
				<br/>제한된 거래:
				<br/>&nbsp;&nbsp;무제한 (선택된 지갑은 구매 및 판매 주문을 모두 할 수 있습니다)
				<br/>&nbsp;&nbsp;구매만 (선택된 지갑은 구매 주문만 할 수 있습니다.)
				<br/>&nbsp;&nbsp;판매만 (선택된 지갑은 판매 주문만 할 수 있습니다.)
				</p>
 			`,
		},
		distribution:{
			// name:'DEX 자산 할당',
			name:'자산 할당',
			sub:'지정된 자산은 바인딩된 메인 지갑에서 서브 지갑 생성 도구에서 만든 서브 지갑으로 할당할 수 있습니다.',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>지정된 자산은 바인딩된 메인 지갑에서 서브 지갑 생성 도구로 생성된 서브 지갑에 할당할 수 있습니다.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>체인 이름: BSC, MATIC, OEC, HECO 등과 같은 지갑이 만들어지는 체인
				<br/>토큰: USDT와 같이 할당할 토큰 이름의 약어
				<br/>총 금액: 할당할 토큰의 총 금액.
				<br/>할당 유형: 
				<br/>&nbsp;&nbsp;1-무작위 할당 (각 서브 지갑에 할당된 무작위 비율은 큰 부동 격차를 할당할 수 있습니다)
				<br/>&nbsp;&nbsp;2-평균 부동 (입력하는 부동 범위에 따라, 각 지갑에 할당된 자금은 평균 주위에 할당되고, 0%는 평균 할당이며, >=80%는 무작위 전략에 따라 할당됩니다)
				<br/>&nbsp;&nbsp;3-조건부 할당 (할당할 지갑을 필터링하고 입력한 기준에 따라 할당하세요)
				</p>
			`,
			// <br/>지갑보다 적음: 할당된 지갑을 받아들일 자산 조건을 설정합니다
			// <br/>부동범위: 지갑당 평균 분배
		},
		pool:{
			// name:'Dex자산을 모으기',
			name:'자산을 모으기',
			sub:'서브 지갑에서 메인 지갑으로 지정된 자산을 수집하세요',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>서브 지갑에서 지정된 자산을 메인 지갑으로 수집하세요.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>체인 이름: BSC, MATIC, OEC, HECO 등과 같은 지갑이 만들어지는 체인
				<br/>토큰: USDT, BNB 등과 같이 수집할 토큰 이름의 약어
				<br/>총 금액: 수집해야 하는 토큰의 총 금액.</p>
			`,
		},
		empower:{
			name:'DEX 지갑 승인',
			sub:'메인 계정에 연결된 메인 계정이나 서브 지갑이 특정 토큰을 거래하도록 승인하세요',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">사용 시나리오：</span>이 도구를 사용하면 메인 계정에 연결된 메인 계정이나 서브 지갑이 특정 토큰을 거래할 수 있도록 승인하십시오.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">파라메터 설명：</span>
				<br/>토큰 인증: 토큰 값을 입력하세요</p>
			`,
			// <br/>승인 유형: 서브 지갑 승인, 메인 지갑 승인.
		},
		// dex子钱包管理
		manage:{
			name:'DEX 지갑 관리',
			sub:'DEX 서브 지갑 만들기, DEX 자산 할당, DEX 자산 수집',
		},
		// dex子钱包刷新
		refresh:{
			name:'서브 지갑 새로 고침',
			sub:'서브 지갑 잔액 정보를 수동으로 새로 고치세요',
		},
		// 导入子钱包
		importWallet:{
			name:'서브 지갑 들여오기',
		},
		// NFT子钱包管理
		manageNFT:{
			name:'NFT 지갑 관리',//Dex서브 지갑 관리
			sub:'지갑 만들기, 자산 할당, 자산 수집',
		},
	},
	
	// 数据报表
	data:{
		recent7:'지난 7일',
		recent30:'지난 30일',
		allocation:'자산 배분',
		dailyTrading:'일일 거래량',
		gas:'수수료',
		buy:'사',
		sell:'팔',
		curve:'자산 동향',
		nftWait:'nft는 잠시 지원하지 않습니다만, 기대해주세요!',
		nftWait2:'잠시 nft 거래소 데이터 통계를 지원하지 않오니, 많은 기대 바랍니다!',
		endTime:'도표 데이터가 종료하다 ',
		export:'내보내기',
	},
	// 资产报表
	property:{
		propertyEmpty:'자산 보고서를 찾을 수 없습니다',
		selectTime:'시간 범위',
		selectTimeTip:'시간대를 선택하세요',
		option1:'근 한 달',
		option2:'근 3개월',
		option3:'근 6개월',
	},

	// 报警系统
	alarmSystem:{
		addAlarm:'새 경보기를 만들다',
		telegram:'텔레그램 바인딩',
		parameter:'파라메터 설정' ,
		currentState:'현재 상태',
		operation:'경보기 운행 중',
		stopped:'경보기 정지되었습니다',
		noData:'사용 가능한 알림이 없습니다.',
		priceThan:'가격 최고치',
		priceBelow:'가격 최저치',
		less:' 최저치',
		mutation:'가격 변동의 정도',
		conditions:'차익거래 로봇 탐지',
		addTrigger:'새 알림 추가',
		editTrigger:'알림 편집',
	},

	// 续费开通
	renew:{
		account:'개인 계좌',
		balance:'지갑 잔고',
		balanceDetail:'잔액 내역',
		vipNow:'현재 패키지',
		remaining:'남은 시간은 {time}',
		expired:'기한이 지났음',
		remainingMonth:'개월 ',
		remainingDay:'일',
		remainingMonths:'개월 ',
		remainingDays:'일',
		continueTime:'갱신 시간',
		contact:'고객 서비스 연결',
		renew:'갱신',// 바로 요금을 갱신합나다
		
		opentime:'개통 기간',
		month:'월',
		individual:' 개',
		month2:'월',
		openings:'활성화된 거래 쌍의 수',
		activate:'즉시 개통합니다',
		switchPackage:'세트 바꾸기',
		// confirmation:'정보 확인',
		confirmation1:'추가 요금 정보 확인',
		confirmation2:'일반 정보 확인을 전환합니다',
		grade:'계정 등급',
		validity:'충전 후 유효기간',
		openingsTip:'(활성화된 거래 쌍의 수는 로봇이 동시에 작동할 수 있는 거래 쌍의 수    를 나타냅니다)',
		cost:'비용',
		activityCost:'행사 가격: ',
		stillNeed:'추가 비용',
		refund:'환불',
		refundTip:'라이트 지갑(시스템 지갑)으로 환불해드립니다.',//안내: 
		rechargeNeed:'추가 충전',
		rechargePay:'充值支付',
		rechargePayTip1:"【注:点击'充值支付'后系统将暂时锁定您的钱包，检测到您的充值动作后自动续费，然后解锁您的钱包。（若您想中断您的当前操作并解除钱包锁定，请您联系客服处理！）】",//充值支付提示-续费
		rechargePayTip2:"【注:点击'充值支付'后系统将暂时锁定您的钱包，检测到您的充值动作后自动切换您所修改的套餐，然后解锁您的钱包。（若您想中断您的当前操作并解除钱包锁定，请您联系客服处理！）】",//充值支付提示-切换套餐

		package:'패키지 혜택',
		include:'환불',
		origin:'원가 ',

		subBalance:' 잔고',
		// 充值
		rechargeType:'속비 기록',
		rechargeAddress:'충전 주소',
		copyAddress:'주소 복사',
		tip:'제시',
		rechargeTip1:'USDT만 확인해 주세요 ( {type} ) 이 주소로 보내 주십시오. 다른 토큰이 이 주소로 전송되면 회수되지 않으며 보상이 없습니다. 블록이 확인된 후, 시스템은 예금 성공을 알릴 것입니다.',
		// rechargeTip2:'번개지갑은 충전 기능을 지원하지 않습니다. 불편하신 점 양해 바랍니다. 도움이 필요하시면 비즈니스 고객센터로 연락하시기 바랍니다.',
		renewRecord:'충전 속비 기록',//충전 기록
		only5:'（가장 최근의 5개의 레코드만 보여줍니다 ）',
		only10:'（가장 최근의 10개의 레코드만 보여줍니다 ）',
		renewAddress:'주소',//요금 추가 주소
		renewAmount:'금액',//연체금액
		renewTime:'갱신 시간',
		renewSource:'체인',//재비의 원천
		renewState:'요금 갱신 상태',
		renewSuc:'성공',
		endTime:'기한 (계정)',
		blockQuery:'블록 검색',
		// 提现
		address:' 주소',
		// total:'총잔액',
		min:'최소 현금 인출 금액',
		service:'수수료',
		withdrawalType:'지갑',
		withdrawalChain:'현금 인출 체인',
		withdrawalAmount:'현금 인출 금액',
		withdrawalHolder:'최소 50usdt 이므로 숫자를 기입해 주십시오',
		withdrawalAddress:'현금 인출 주소',
		withdrawalTip1:'반드시 정확한 usdt조소를 기입해 주세요 ( {type} ) 사용자의 개인적인 문제로 인해 손실된 자산에 대해 LIGHT는 어떠한 책임도 지지 않습니다. 이러한 문제는 다음과 같이 제한될 수 있지만 제한되지 않습니다: 입력한 인출 주소는 자신의 지갑 주소가 아닙니다; 또는 필요한 매개 변수가 정확히 채워지지 않습니다. 사용자 자산의 안전을 보장하기 위해, 대규모 인출은 보통 10-30분 이내에 수동 검토가 필요합니다.',
		// withdrawalTip2:'번개지갑은 잠시 현금 인출 기능을 지원하지 않습니다. 불편을 드려 양해 바랍니다. 도움이 필요하시면 비즈니스 고객센터로 연락하시기 바랍니다.',
		withdrawalTip2:'반드시 정확한 usdt조소를 기입해 주세요 사용자의 개인적인 문제로 인해 손실된 자산에 대해 LIGHT는 어떠한 책임도 지지 않습니다. 이러한 문제는 다음과 같이 제한될 수 있지만 제한되지 않습니다: 입력한 인출 주소는 자신의 지갑 주소가 아닙니다; 또는 필요한 매개 변수가 정확히 채워지지 않습니다. 사용자 자산의 안전을 보장하기 위해, 대규모 인출은 보통 10-30분 이내에 수동 검토가 필요합니다.',
		withdrawalRecord:'현금 인출 기록',
		withdrawalTime:'현금 인출 시간',
		currentState:'현재 상태',
	},
	// 续费时长
	renewTime:[
		'월',
		'분기',
		'반년',
		'년'
	],
	renewTime2:[
		'월',
		'분기 (8할)',
		'반년 (7할)',
		'년 (6할)'
	],
	// 充值续费提现状态
	state:[
		'后台续费成功',//0
		'续费支付中',//1
		'续费成功',//2
		'续费失败',//3
		'修改套餐支付中',//4
		'修改套餐成功',//5
		'修改套餐失败',//6
		'신청 중',//7
		'성공',//8
		'실패',//9
		'提现审核通过',//10
		'充值归集中',//11
		'充值成功',//12
		'充值失败',//13
	],
	// 等级
	level:[
		'개별 버전 패키지',
		'엔터프라이즈 버전 패키지',
		'전문 버전 패키지',
		'DEX 버전 패키지',
		'NFT 버전 패키지',
	],

	// 用户中心
	user:{
		data:'계좌 번호 정보',
		userName:'사용자 이름',
		lv:'계정 등급',
		upORren:'업그레이드/요금',
		autoRenew:'自动续费',
		autoRenewTip:'自动续费：当账号有效期剩余3天且闪电钱包余额满足续费费用，系统将自动帮您续费，无需您手动操作。',
		help:'초보자 인도',
		security:'보안 설정',
		modifyNum:'번호를 고치다',
		changePwd:'비밀번호 변경',
		bindEmail:'편지함 바인딩',
		changeEmail:'메일박스 수정',
		safeLogin:'보안 로그인',
		ipVer:'IP검증',
		ipRecommend:'닫기 추천하지 않음 ',
		
		language:'언어',
		changeLanguage:'언어 수정',

		model:'모드',
		dark:'야간 모드',
		light:'주간 모드',
	},
	
	// 邀请好友
	invite:{
		code:'초대 코드',
		link:' 초대 링크',
		rules:'추천 규칙',
		rules1:'초대 링크나 코드를 통해 친구를 추천하고 로봇을 시작할 수 있도록 도와주세요.',
		rules2:' 생성된 수익의 20%를 받게 될 것입니다.',
		revenue:'추천 총수익',
		today:'오늘 추천 인원 수',
		total:'총 추천 인원 수',
		record:'초대 기록',
		account:'계정',
		money:'금액',
		pay:'지불 시간',
	},

	// 404
	notFount:{
		msg:'죄송합니다, 페이지가 잘못되었습니다.',
		backBtn:'홈 페이지로 가기',
	},
}