<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	var that;
	import { mapGetters } from 'vuex'
	// import "./assets/style/var.css";
	// api
	import {compareVersion} from '@/api/user'
	export default ({
		name: 'APP',
		// 动态title、meta
		metaInfo(){
			if(this.$route.meta.title){
				// console.log(this.$route.name)
				if(
					this.$route.name=='agreement'||
					this.$route.name=='problem'||
					this.$route.name=='homeContact'||
					this.$route.name=='login'||
					this.$route.name=='safeLogin'||
					this.$route.name=='forget'||
					this.$route.name=='register'
				){
					// 外部页面-无需登录
					return{
						title: this.$t(this.$route.meta.title)+' - '+this.$t('common.title'),
						mate:this.$route.meta.metaData||[]
					}
				}else{
					// 内部页面-登录后访问
					return{
						// title: this.$t(this.$route.meta.title),
						title: this.$t('common.title'),
						mate:this.$route.meta.mateData||[]
					}
				}
			}else{
				return{
					title: this.$t('common.title'),
				}
			}
		},
		created(){
			that=this;
			// console.log(window.location)
			// html中调用淘宝开放接口jsonp
			window['ipCallback']=(obj)=>{
				// console.log('test2',obj.ip)
				this.$store.dispatch('app/setIp',obj.ip)
			}
			// const getIPs = (callback) => {
			// 	var ip_dups = {};
			// 	var RTCPeerConnection = window.RTCPeerConnection
			// 	|| window.mozRTCPeerConnection
			// 	|| window.webkitRTCPeerConnection;
			// 	// var useWebKit = !!window.webkitRTCPeerConnection;

			// 	var mediaConstraints = {
			// 		optional: [{ RtpDataChannels: true }]
			// 	};
			// 	// 这里就是需要的ICEServer了
			// 	var servers = {
			// 		iceServers: [
			// 			{ urls: "stun:stun.services.mozilla.com" },
			// 			{ urls: "stun:stun.l.google.com:19302" },
			// 		]
			// 	};
			// 	var pc = new RTCPeerConnection(servers, mediaConstraints);
				
			// 	function handleCandidate(candidate) {
			// 	var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
			// 	var hasIp = ip_regex.exec(candidate)
			// 	if (hasIp) {
			// 	var ip_addr = ip_regex.exec(candidate)[1];
			// 	if (ip_dups[ip_addr] === undefined)
			// 		callback(ip_addr);
			// 		ip_dups[ip_addr] = true;
			// 	}
			// 	}
			// 	// 网络协商的过程
			// 	pc.onicecandidate = function (ice) {
			// 		if (ice.candidate) {
			// 			handleCandidate(ice.candidate.candidate);
			// 		}
			// 	};
			// 	pc.createDataChannel("");
			// 	//创建一个SDP(session description protocol)会话描述协议 是一个纯文本信息 包含了媒体和网络协商的信息
			// 	pc.createOffer(function (result) {
			// 	pc.setLocalDescription(result, function () { }, function () { });
			// 	}, function () { });
			// 	setTimeout(function () {
			// 	var lines = pc.localDescription.sdp.split('\n');
			// 	lines.forEach(function (line) {
			// 		if (line.indexOf('a=candidate:') === 0)
			// 		handleCandidate(line);
			// 	});
			// 	}, 1000);
			// }
			// getIPs((ip)=>{
			// 	console.log('test1',ip)
			// })
		},
		mounted() {
			// 主题切换
			document.getElementsByTagName('html')[0].setAttribute('data-theme',this.theme)
			// if(this.theme=='light'){
			// 	document.getElementById('themeLinkId').href = './static/theme/light.css';
			// }else{
			// 	document.getElementById('themeLinkId').href = './static/theme/dark.css';
			// }

			// 版本升级提示
			// this.compareVersion()
		},
		methods:{
			// 版本升级提示
			compareVersion(){
				compareVersion({
					version:this.version,
					ignore_version:localStorage.getItem('ignore_version')||this.version,
				})
			},
		},
		computed:{
			...mapGetters(['theme','version']),
			path(){
				return this.$route.path
			},
		},
		watch:{
			// 主题切换
			theme:(now)=>{
				document.getElementsByTagName('html')[0].setAttribute('data-theme',that.theme)
				// if(now=='light'){
				// 	document.getElementById('themeLinkId').href = './static/theme/light.css';
				// }else{
				// 	document.getElementById('themeLinkId').href = './static/theme/dark.css';
				// }
			},
			path:function(now,old){
				// console.log(old+'=>'+now)
				if(old=='/stocks'){
					// 清除手动操盘
					localStorage.setItem('stocksIsFull','0')
				}
				this.compareVersion()
			},
		},
	})
</script>

<style type="text/css">
	@import url("~@/assets/font/iconfont.css");
</style>