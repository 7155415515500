module.exports = {
	common: {
		title:'Lightning',
		endtime:' Valid until',
		contact:'Contact us',
		exchange:'Exchange',
		pair:'Symbol',// Trading pair / Pair
		opensea:'NFT Token',
		currency:'Currency',
		available:'Available',
		frozen:'Allocated',
		change:'Change',
		total:'Total',
		currentOpen:'Current Trading Pair',
		collection:'Favorite Trading Pair',
		commonly:'Favorites',
		explain:' Explanation',
		addTile:'Add ',
		editTitle:'Edit ',
		description:'Function Manual',
		num:'Amount',//Quantity
		pirce:'Price',
		purchase:'Buy',
		sellout:'Sell',
		closed:'Close Order',//Filled
		direction:'Direction',//Side
		time:'Time',
		operation:'Operate',
		recharge:'Recharge',
		withdrawal:'Withdrawal',
		exchangeSelect:'Select an Exchange',
		whole:'All',
		agreement:'User Agreement',
		mainAddress:'Main-wallet address',
		subAddress:'Unauthorized address',
		gasCurrency:'Gas currency',
		token:'Token authorize list',
		filter:'Filter',
		content:'Content',
		selectTime:'Select Time',
		valuation:'Valuation',
		proportion:'Proportion',
		gas:'Service fee',
		targetAddress:'Target Address',
		balance:'Balance',

		// 按钮
		renew:'Recharge',
		open:'permission',//Apply for authorization/ Relase for authorization //permission/authorization
		handicap:'Enter Pair',
		into:'Enter',
		selectOpen:'Select Trading Pair',
		back:'Back',
		next:'Next',
		pre:'Previous',
		continueAdd:'Continue adding',
		add:'Add',
		edit:'Edit',
		modify:'Modify',
		bind:'Bind',
		remove:'Unbind',
		removeBind:'Unbind',
		toOpen:'Apply for Authorization',
		backlist:'Back to List',
		search:'Search',
		confirm:'Confirm',
		beforeDay:'Day Before',
		nextDay:'Day After',
		submit:'Submit',
		cancel:'Cancel',
		confirm2:'Confirm',
		save:'Save',
		saveRun:'Save and Start',
		copy:'Copy',
		cancelOrder:'Cancel Order',//Cancel
		stop:'Stop',
		run:'Start',
		del:'Remove',
		toBind:'email bind',
		toAuthorize:'To authorize',//'Go to authorization',
		authorize:'authorize',
		toAllocation:'Allocate',
		toErrLog:'Error Log',
		allotAuthorize:'Allocate and Authorize',
		directAuthorize:'Direct Authorize',
		ignore:'Ignore',
		viewBtn:'View',
		refused:'Ignore',
		update1:'Update',
		update2:'Update',
		downLoad:'Export',
		// 单选
		yes:'YES',
		no:'NO',

		// 输入框占位符
		select:'Select',//Please select 
		input:'Enter',//Please enter 
		value:'Value',//Amount
		minValue:'Min Value',
		maxValue:'Max Value',
		inputVerification:'Please enter the verification code',
		selectdate:'Select Date',
		startdete:'Start Date',
		enddete:'End Date',
		keyword:'Keyword',//Please enter a keyword
		

		telegram:'Telegram',
		pwd:'Password',
		email:'Email',
		emailAddress:'Email Address',
		// newPhone:'New Phone Number',
		newPwd:'New Password',
		confirmNewPwd:'Confirm new password',
		newEmail:'New Email',
		verification:'Email verification code',
		verification_s:'Verification Code',
		countDown:'Countdown',
		sendVerification:'Send',//Send Verification Code
		resend:'Resend',

		max:'Maximum',
		min:'Minimum',

		// sub
		userName:'Username',
		login:'Login',
		register:'Register',

		documentation:' Documents',
		freeRegistration:'Register',//Register for free

		loding:'Loading',
		noMore:'No More Information',
		noData:'No Data',
		
		subManage:'Manage Wallet',
		toSeeBtn:'View',
		enabled:'Enabled',
		notEnabled:'Disable',

		// 交易记录
		transactionRecord:'Transaction Record',
		address:'Address',
		tradingDirection:'Trading Direction',
		tradingAddress:'Wallet Address',
		transactionAverage:'Price',//Average Deal Price
		transactionNum:'Deal Quantity',
		transactionAmount:'Total Deal Amount',
		orderStatus:'Order Status',
		orderStatus1:'Fail',
		orderStatus2:'Success',
		orderStatus3:'Trading in',
		viewTX:'View TX',

		query:'Search',
		toLogin:'to Log in',
		loginAgain:'Log in again',
		refresh:'refresh',
		toClose:'To shut down'
	},
	head:{
		oldWeb:'Old light 5.0 system entry',
		// dayMode:'Light',// Light / Day theme
		// nightMode:'Dark',// Dark / Night theme
		lv:'User Level',
		center:'User Center',
		invite:'Referral',
		notice:'Latest announcements',
		out:'Log out',
		outTip:'Confirmed to log out?',
	},
	aside:{
		list:[
			'Account List',
			'Manual Operate',
			'Trading Robot',
			'Robot Log',
			'Data Report',
			'Assets Report',
			'Alert',//'Alarm System',
			'Settings',
			'User Center',
			'Referral',//'Invite',
			'Support',
			'Tutorial',//"Beginner's Toturial",
			'Contact Us',
		],
		renew:'Recharge',//Recharge to release authorization
	},
	// 官网相关
	website:{
		index:{
			lightMM:'Lightning Market Making System',
			mm:'Market Making System',
			// sub:'Smooth operation for 6 years. The most secure and powerful matchmaker in the world. All major Dexes are now supported!',
			sub:'Smooth operation for 6 years. The most secure and powerful market making system in the world. All major Dexes are now supported!',
			project:'Project partners',
			multiChain:'Support for multi-chain Dex',
			// f2
			fullyAutomatic:'Fully automatic price fluctuations',
			followSystem:'Market Syncing System',
			sellSuck:'Range trading to Arbitrage',
			assetWarning:'Asset Alarm System',
			// f3
			packages:'Packages',
			recommend:'Recommand',
			month:'Month',
			// f4
			features:'Feature of product',
			featuresDetail:'Safe, Powerful, and Convenient',
			safe:'Safe',
			safeSub:'Assets are deposited on exchanges, and account information is multiple encrypted.',
			power:'Powerful',
			// powerSub:'The core program and logic of Light robot have years of research and development.',
			powerSub:'The core program and logic of Lightning Market Making System have years of research and development.',
			convenient:'Convenient',
			convenientSub:'Aggregate management of multiple accounts enables you to operate freely on multiple terminals.',
			efficient:'Efficient',
			efficientSub:'Efficient operation',
			// f5
			customers:'Target customers',
			who:'Who do we serve?',
			market:'Market maker',
			incubator:'Incubator',
			// foot
			links:'Links',
			// storm:'Storm Orders Track',
			// lightning:'Lightning Quantify',
			follow:'Follow Quantify',
			service:'Service Center',
			cooperation:'Business Cooperation',
			problem:'Common Issues',
			terms:'Terms and Conditions',
			privacy:'Privacy Policy',
		},
		agreement:{
			protocol:'User Agreements',//USER PROTOCOL
			content:`
			<p>The Light system is developed and operated by Singapore Jumu Capital co. ltd. 

				The company's website http://www.llkkz.net (hereinafter referred to as "this website" or "website") is a service that provides blockchain industry services (hereinafter referred to as "the service"). ” or “Services”) platform. 
			
				In order to protect your rights, please read and fully understand the entire contents of the user protocol before registering or using this website.
			</p><br/>
			<h3>1. General</h3>
			<p>
				1.1 The User Procotol (hereinafter referred to as the "Protocol") consists of the main text, the Privacy Policy, and various rules, statements, instructions, etc. that have been published or may be published in the future on this website.<br/>

				1.2 Before using the services provided by this website, you should read this protocol carefully. 

				If you have any incomprehension or other necessity, please consult a professional lawyer. 

				If you do not agree to this agreement and/or its modifications at any time, please stop using the services provided by this website or log in to this website immediately. 

				Once you log in to this website, use any service of this website or any other similar behavior, you have understood and fully agree to the contents of this protocol, including any modification made by this website at any time to this protocol.<br/>

				1.3 You can become a user of this website (hereinafter referred to as "user") by filling in relevant information in accordance with the requirements of this website and successfully registering through other relevant procedures. 

				Clicking the "Agree" button during the registration process means that you electronically sign;

				Or when you click any button marked "Agree" or similar in the process of using this website, or when you actually use the services provided by this website in other ways permitted by this website, you are fully understand, agree and accept all the terms under this protocol. 

				As far as this agreement is concerned, the absence of your handwritten written signature will have no effect on your legal binding force.<br/>

				1.4 After you become a user of this website, you will obtain a user account and corresponding password, and the user account and password shall be kept by the user;

				You are legally responsible for all activities and events that occur with your account.<br/>

				1.5 Only users who become users of this website can use the blockchain industry services provided by this website; non-registered users can only browse the content of the website.<br/>

				1.6 By registering and using any of the services and functions provided by this website, you will be deemed to have read, understood and:<br/>

				1.6.1 accept all terms and conditions of this Protocol;<br/>

				1.6.2 You confirm that you are at least 18 years of age or the legal age to enter into a contract under different applicable laws.

				Your registration on this website, posting of information, etc. to accept the services of this website shall comply with the relevant laws and regulations of the sovereign country or region that has jurisdiction over you, and be fully capable of accepting these terms；<br/>

				1.6.3 You confirm that the information provided during registration is true and accurate;<br/>

				1.6.4 You agree to abide by any relevant laws;<br/>

				1.6.5 You agree that at no time shall you engage in or participate in acts or activities that damage this website or the Light Robot, whether or not in connection with the services provided by this website
			</p>
			<br/>
			<h3>2. Amendments to the Protocol</h3>
			<p>2.1 Lightning reserves the right to revise this protocol from time to time and make announcements in the form of website announcements, without notifying you separately.

			Once the changed protocol is published on the website, it will automatically take effect immediately.
			
			You should browse and pay attention to the update time and update content of this Protocol from time to time.
			
			If you do not agree with the relevant changes, you should immediately stop using the services of this website.
			
			If you continue to use the services of this website, it means that you accept and agree to be bound by the revised protocol.
			</p>`,
		},
		problem:{
			question1:'How does the system ensure the security of account assets?',
			// answer1:'Double protection: 1. Light robot has multiple protection modules to deal with different attacks and arbitrage behaviors. 2. The alarm system can monitor the panel according to your settings, and it will stop all robots immediately if there is an abnormality.',
			answer1:'Double protection: 1. Lightning Market Making System has multiple protection modules to deal with different attacks and arbitrage behaviors. 2. The alarm system can monitor the panel according to your settings, and it will stop all robots immediately if there is an abnormality.',
			// question2:'Can one Light system account be connected to multiple exchange market-marking accounts?',
			question2:'Can one Lightning Market Making System account be connected to multiple exchange market-marking accounts?',
			// answer2:'The Light system currently supports more than 60 exchanges. You can bind the trading accounts of multiple exchanges in a single Light account to complete the aggregation management of multiple accounts.',
			answer2:'The Lightning Market Making System currently supports more than 60 exchanges. You can bind the trading accounts of multiple exchanges in a single Light account to complete the aggregation management of multiple accounts.',
			question3:'How to check the profit and loss of assets?',
			// answer3:'The Light system will record the account balance of the day at 0:00 every day. You can check the historical daily profit and loss by comparing the daily balance changes.',
			answer3:'The Lightning Market Making System will record the account balance of the day at 0:00 every day. You can check the historical daily profit and loss by comparing the daily balance changes.',
			question4:'How to retrieve the forgotten password?',
			answer4:'Please contact customer service, the customer service will check the account ownership with you and reset the login password for you. After logging in, you can change the password in the personal center.',
			question5:'Others',
			// answer5:'If you have any use problems or suggestions on the product, we very much welcome you to contact us, we will do our best to meet your needs! Thank you for your support of Light Robot.',
			answer5:'If you have any use problems or suggestions on the product, we very much welcome you to contact us, we will do our best to meet your needs! Thank you for your support of Lightning Market Making System.',
		},
		contact:{
			text1:'The Light cloud system was developed by Singapore LIGHT Tech. in 2016 to summarize the mature trading techniques in the cryptocurrency, and it took one year to design and develop it. As the most professional market-making/trading robot supplier in the cryptocurrency, the system has been running for 6 years, serving hundreds of project-side market-making teams.',
			// text2:"Light cloud system currently supports more than 200+ CEXes. The latest {version} system has taken the lead in supporting a number of Dexes, becoming the industry's leading market value robot service provider. There are mutiple system strategies and functions available, it is the best choice for your market making/trading.",
			text2:"Light cloud system currently supports more than 200+ CEXes. The latest {version} system has taken the lead in supporting a number of Dexes, becoming the industry's leading market making system service provider. There are mutiple system strategies and functions available, it is the best choice for your market making/trading.",
			business:'Business Cooperation',
			service:'Customer Service',
		}
	},
	// 登录相关模块
	login:{
		loginTitle:'Login',
		forget:'Forgot password?',
		toRegister:'To sign up',//No account? Go register
		agree:'Agree with Lightning',
		version:'Version',//System 
		// 忘记密码
		reset:'Reset Password',
		confirm:'Confirm Password',
		confirmReset:'Confirm to reset',
		// 注册
		register:'Sign up',
		toLogin:'Already have an accont? Go to login',
		safeLogin:'Secure login',
	},
	// 输入框提示
	tip:{
		usernameEmpty:'Please enter Username',
		accountVer:'Username must be more than 6 characters, and contain both letters and numbers',
		// phoneEmpty:'Please enter a phone number',
		// phoneVer:'Please enter a valid phone number',
		emailEmpty:'Please enter email address',
		emailVer:'Please enter the correct email address',
		codeEmpty:'Please enter the verification code',
		pwdEmpty1:'Please enter a password',
		pwdEmpty:'Please enter a new password',
		pwdVer:'Password must be more than 6 characters, and contain both letters and numbers',
		pwd2:'The two passwords do not match',
		commonVer:'Must more than 6 characters, and contain both letters and numbers',
		again:'Please check the new password once again',
		invite:'Referral Code (Optional)',
		check:'Please check the user policy and privacy agreement',
		tips:'Tips',
		expired:'The authorization has expired, if you need to use,  please renewal!',
		waiting:'Coming soon...',
		registerSuc:'Registration success!',
		copysuccess:'Copy success!',
		copyerr:'The current browser does not support copying!',
		// 收藏
		collectionSuc:'Added to Favorites',
		cancelCollectionSuc:'Remove form Favorites',
		// 账号列表
		accountEmpty:'Please enter Username！',
		addSuc:'Added sucess!',
		addErr:'Added failed!',
		delTip:'Confirmed to delete?',
		delSuc:'Deleted success!',
		delErr:'Deleted Failed!',
		// 手动操盘
		selectExchange:'Select exchange',//'Please select an exchange',
		enterPair:'Please enter a symbol',
		enterCollect:'Please enter the collection',
		numEmpty:'A valid amount is required!',
		priceEmpty:'A valid price is required!',
		buySuc:'Buy-in success!',
		sellSuc:'Sold-out success!',
		handicap:'A valid trading pair is required!',
		cancelOrder:'Confirm to cancel order?',
		cancelSuc:'Order cancel success!',
		manuallyBuy:'Confirm to manually place buy order?',
		manuallySell:'Confirm to manually place sell order?',
		needSell:'Need to sell {num}',
		needBuy:'Need to buy {num}',
		total500:'Total order volume maximum 500',
		// 交易记录
		recordListTip:'Records within 3 months only',
		// 子钱包管理
		privateKey:'Please enter the private key of sub wallet!',
		toSee:'Check the log to view detailed results!',
		// searchEmpty:'The selected address is not contained in sub wallets',
		toMainRefresh:'Confirm to refresh main wallet?',
		createSuc:'<div>Sub-wallet has been created!<br/><span style="color:#E65B1B">【Note:Please allocate funds to your sub-wallets before using it. 】</span></div>',
		num0:'The number cannot be 0',
		totalQuantity0:'Total quantity cannot be 0',
		// 操盘机器人
		saveSuc:'Save success!',
		saveErr:'Save failed!',
		startRobot:'Confirmed to start the robot?',
		startSuc:'Start success!',
		startErr:'Start failed!',
		stopRobot:'Some issues might be caused when the robot terminated. Confirmed to terminate the robot?',
		stopSuc:'Terminated success!',
		stopErr:'Terminated failed!',
		dataErr:'Parameters incomplete!',
		operationSuc:'Operation success!',
		toRefresh:'Confirmed to refresh the balance?',
		refreshSuc:'Refresh success!',
		processing:'Uploaded success! Processing...',
		calculationSuc:'Calculation success:',
		noRobot:'There are currently no robots in operation!',
		closingErr:'Closing, please hold on...',
		closing:'It will automatically shut down after {time} seconds',
		mainSaveRunTip:'The current transaction authority of the main wallet is enabled. Once the robot sets up, there is a possibility for the main wallet to place an order in the transaction. If you do not want the main wallet involved in transactions, please disable this function and restart the robot.',
		mainRunTip:'The transaction authority of the main wallet is enable, and the main wallet would participate in placing order. If you do not want the main wallet involve in transactions, please go to [Manage Sub Wallet] to disable transaction authority.',
		// 数据报表
		exportSend:'Transaction details will be sent to your email later',
		sendSuc:'Send successfully, please note to check!',
		noEmail:'You are not currently bound to the mailbox, please bind the mailbox after use',
		// 资产报表
		export:'Export the {exchange} exchange report',
		// 报警系统
		noTeleram:'There is no binding Telegram, to bind Telegram',
		startAlarm:'Confirmed to start Alert?',
		stopAlarm:'Some issues might be caused when the Alert teminated. Confirmed to terminate the alart system?',
		// 续费开通
		// discountTip:'From November 1, 2022 to December 30, 2022, you can enjoy a 5% discount if you renew your wallet system',//活动折扣提示
		discountTip1:'Daily activities: 20% off for quarterly payments, 30% off for half-yearly payments, and 40% off for annual payments.',
		// discountTip2:'Limited-period activities: From November 1, 2022 to December 30, 2022, you can enjoy a 5% discount if you renew your wallet system.',
		renewalSuc:'Renewal success!',
		// renewalErr:'Renewal failed!',
		renewalInsufficient:'Failed to renew, your wallet balance is insufficient, please recharge!',
		setPackageSuc:'Package modification success!',
		// setPackageErr:'Package modification failed!',
		setPackageInsufficient:'Failed to modify the package, your wallet balance is insufficient, please recharge!',
		txSeachErr:'The renewal hash has not been synchronized, please try again later!',
		txSeachErr2:'The hash has not been synchronized, please try again later!',
		notSupported:'Light-wallet does not support viewing!',
		withdrawRestrictions:'The minimum withdrawal amount is 50 USDT!',
		addressEmpty:'A valid withdraw address is required!',
		VerificationEmpty:'A valid verification code is required!',

		withdrawalSuc:'Your withdrawal application has been submitted, please check the status of the application in the withdrawal record',
		// withdrawalErr:'Withdraw failed',

		editSuc:'Edited success!',
		editErr:'Edited failed!',

		// 用户中心
		emailRepeat:'The new email is duplicate with the old email!',
		notBindEmail:'Please bind mailbox first!',
		ipClose:'The account may be at risk after IP authentication is disabled. Are you sure to disable it?',
		ipCloseTip:'【Note: IP authentication is not required within one week after this function is disabled, and it will be automatically enabled again after one week】',
		remove:'Telegram unbinding successful!',

		// req
		ipErr:'The currect IP is invalid. To prevent error, please login again!',
		unknownErr:'The system is busy, please try again later',//'Unknown Error',
		// 1003 1004
		update:'Update: ',
		// 2015
		ipVerTip:'【Note: You can go to the user center to turn off IP authentication (not recommended)】',
		
		confirmAuthorize:'Confirm DEX wallet authorization token:',
		authorizeSuc:'Authorization success, please try again!',
		mainAuthorizeErr:'【Note: please check the GAS fee in main-wallet.】',
		authorizeErr:'【Note: wallet authorization failed due to insufficient gas. Please go to the manage wallet interface to confirm whether the failed wallet has sufficient gas.】',
		// 2109
		authorizeErr2:'【Note 1: Wallet authorization failed because of insufficient gas, please click allocation authorization or go to the  manage wallet interface to check the balance of wallet gas.】<br/>【Note 2: If you are a newly imported wallet or a newly opened wallet, please click direct authorization to confirm that the wallet gas is sufficient.】',
		// 2172
		authorizeErr3:'【Note: Wallet authorization failed because of insufficient gas, please click to allocate or go to the manage wallet interface to check the balance of wallet gas.】',
		
		// 2176 获取余额、充值地址
		renewErr:'Payment renewal wallet has not been generated, please contact customer service, please understand the inconvenience brought to you!',
		renewTip:'Please contact customer service for renewal subscription!',
		renewTip2:'Manual renewal is not supported at the moment. Please understand the inconvenience brought to you. Please contact the business customer service.',
		// renewTip3:'Your account can not use the renewal function, please contact the business customer service, please understand the inconvenience caused to you.',
		// renewTip4:'Your account can not modify the package, please contact the business customer service, please understand the inconvenience brought to you.',
		renewTip5:'Please understand the inconvenience caused to you. Please contact the business customer service.',
		renewTip6:'There is a recharge in progress, and your wallet is temporarily locked. Please try again later.You could visit the Recharge page to check the transaction records of your wallet.We apologize for the inconvenience caused. Please contact the customer service if you have any questions.',

		// 服务器错误
		serverErr:'Server busy, please try again later! ',
		// 服务器错误2 请求超时
		timeout:'Request timed out, Please check whether your network environment is normal and try again later!',
	},

	// 账号列表
	account:{
		title:'Account List (Each exchange is limited to one account)',
		create:'Create an account',
		bind:'Bind a new account',
		toCreate:'No account yet, go to create...',
		newAccount:'New Account',
		accountName:'Account Name',
		network:'Select a network',
		whiteList:'IP whitelist',
		tip:'Please note that no spaces were added when filling in the API Key, Api Secret, Secret Key and Private Key',
		success:'Account created success!',
		sub:'You can view the accounts you have added via the account list',
		editAcc:'Edit account',
		delAcc:'Delete account',
		
		walletAddress:'Wallet address',
		privateKey:'Private Key',
		apiPWD:'API password',
		payPWD:'Payment password',
		api:'API name',
	},

	// 手动操盘
	stocks:{
		tradingView:'K-Line Chart',
		toolSelect:'Select Trading Tools',
		tools:'Trading Tools',//Quantification tools/trading tools 取决于要不要强调量化，意译的话我认为trading tool就可以
		order:'Manual Order',//Manual orders
		mainOrder:'Master Wallet Order',
		orderTip:'DEX manual order is to call the main wallet to place the order, and the sub-wallet will not involve. If you want to place an order using only sub-wallets, please go to Quantitative Tools - "Batch Order" to operate',
		//DEXtool:'DEX transaction and quantification tools',
		check:'Price range of pending orders',//买单挂单价格范围Buy orders
		sale:'Price range of pending orders',//卖单挂单价格范围Sell orders
		priceRange:'Price range',
		quantity:'Order quantity',
		quantityRange:'Quantity range',
		total:'Total order volume',
		decimal:'Price decimal characters',
		target:'Target price',
		walletsNumber:'Wallets number',
		volume:'Trading volume',
		floatingRange:'Floating range',
		strategy:'Trading strategies',
		strategyDefault:'Default',
		strategyCondition:'Condition',
		transactionType:'Transaction type',
		floatTip0:'The floating range is 0%. Wallets that place bulk orders will place an average number of orders',
		chain:'Chain Name',
		refresh:'Balance Refresh',
		wallets:'Wallets volume',
		totalQuantity:'Total quantity',
		totalCumulative:'Total cumulative',
		token:'Authorize token',
		walletType:'Wallet authorization type',
		orderManagement:'Orders manage',
		common:'Common Market',
		advanced:'Advanced Market',
		coins:'Tokens needed to sell, to achieve the target price',
		coinsMoney:'Tokens needed to buy, to achieve the target price',
		investors:'Other users amount',
		investorsTotal:'Other users cumulative',
		myself:'My amount',
		
		robotMange:'Robots manage',

		buy:'Buy',
		sell:'Sell',
		subWallet:'Sub wallets',
		mainWallet:'Main wallet',
		
		accountAssets:'Account assets',
		mainAssets:'Main wallets assets',
		subAssets:'Sub wallet assets',
		mainAssetsNFT:'Main wallets nft assets',
		subAssetsNFT:'Sub wallet nft assets',

		// nft
		contractAddress:'Contract Address',
		date:'Created Date',
		items:'items',
		owners:'owners',
		totalVolume:'total volume',
		floor:'floor price',
		average:'average price',
		currency:'currency',
		more:'See more',
		less:'See less',
		
		// 选择盘口 常用盘口
		manual:'Manual operate',
		// help
		know:'I have known...',
		welcomeText:[{
				title:'How to switch the trading pair?',
				text:'Through the current button, you can manually enter the exchange symbol to switch.'
			},{
				title:'Create / view exchange account',
				text:'Click the current button to create / query the currently opened exchange account, including corresponding sub wallet management, modification and deletion of account, etc.'
			},{
				title:'Robot settings',
				text:'Click [Trading robot] to start the desired robot.When some robots cannot start, it can be unlocked and start by upgrading to corresponding authorization.'
			},{
				title:'asset management',
				text:'Through the asset report, you can clearly view all asset information corresponding to the current exchange account.'
			},
		],
	},
	// 子钱包管理
	subManage:{
		totalAssets:'Total assets of all sub wallets',
		useAssets:'Enabled assets in subwallets',
		notUseAssets:'Unenabled assets in subwallets',
		
		wallets:'Number of sub-wallets',
		totalAmount:'Total Amount',

		mainWallet:'Main wallets',
		address:'Address',
		chain:'Chain',
		assets:'Assets',
		approveTokens:'Approve tokens',
		participate:'Transaction authority',
		transfer:'Assets Transfer',
		transferAndAuthorize:'Transfer and Authorize',
		subWalletList:'Sub wallet list',
		enable:'Whether to enable',
		currentState:'Current Status',
		start:'Start',
		stop:'Stop',
		// 创建子钱包
		createTip:'【Note: For the security of your assets and account, the wallet private key generated by the system cannot be exported】',
		createTip2:'【Note: After sub-wallets create, please disable the transaction authority of the main wallet in time if there is no need for the main wallet to involve in the transaction of robots and quantitative tools】',
		// 导入子钱包
		key:'Please copy and paste the sub-wallet private key (paste on alternate lines)',
		currently:'Currently entered ',
		entry:' line',
		importTip:'【Note: For the security of your asset and account, the saved private key cannot be exported. Please back it up】',
		importTip2:'【Note: After sub-wallets import, please disable the transaction authority of the main wallet in time if there is no need for the main wallet to involve in the transaction of robots and quantitative tools】',
		// 分拨
		distributionStrategy:'Distribution Strategy',
		strategy:'Strategy',
		randomAllocate:'The random allocation',
		averageAllocate:'Average floating allocation',
		conditionalAllocate:'Conditional allocation',
		floatRange:'The floating range',
		walletBelow:'The wallet below',
		floatTip1:'The floating range is 0% and will be equally distributed to each subwallet',
		floatTip2:'If the floating range is greater than or equal to 80%, the system adjusts the allocation policy to random allocation',
		fundsSource:'funds Source',
		// 资金划转
		transferTip:'【Note: For the security of your assets, the target address of fund transfer can only be the main wallet or sub-wallet of your account】',
		single:'Single Wallet Transfer',
		multiple:'Wallet Allocation',
		targetAddressSame:'The destination address is the same wallet, and the same wallet cannot be transferred',
		safeVer:'Security verification',
		safeVerTip:'【Note: Your current operation involves a large amount of money. To ensure the security of funds, you need to verify your email address】',

		// 来源
		enabled:'Current Sources Enabled',
		create:'System Creates',
		import:'External Import',
		source:'sub-wallets category',
		onlyCreate:'System Created',
		onlyImport:'Externally Imported',
		allOpen:'Enable All',
		allClose:'Disable All',
		allOpenTip:'Are you sure to open all wallets under the current source?',
		allCloseTip:'Are you sure to close all wallets from the current source?',

		// 交易限制
		transactionRestrictionModify:'Modify Transaction Limit',
		transactionRestriction:'Transaction Limit',
		unlimited:'Unlimited',
		buyOnly:'Buy only',
		sellOnly:'Sell only',
		allUnlimitedTip:"Are you sure to change the transaction limit to 'unlimited' for the currently selected wallet address?",
		allBuyOnlyTip:"Are you sure to change the transaction limit to 'Buy only' for the currently selected wallet address?",
		allSellOnlyTip:"Are you sure to change the transaction limit to 'Sell only' for the currently selected wallet address?",
		limitTip:'交易限制：无限制（钱包参与下单买或者卖随机）、只买（钱包只能参与下单买入）、只卖（钱包只能参与下单卖出）',
		
		// NFT
		openBtnTip:'Click to display NFT assets below the list',
		openBtn:'Open',
		closeBtn:'Close',
		copyAddress:'Copy address to clipboard',
	},

	robot:{
		list:[
			// 1
			{
				name:'Range Trading',//'Sell-high and buy-low robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot buys low and sells high in within the price range provided. Once the price is higher or lower than the range stated, a sell or buy order will be executed appropriately.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Ignore Order Quantity: Ignores the Order Quantity stated and executes each buy/sell order regardless of quantity.
					<br/><br/>Maximum Position Size: If the current position size is larger than that stated here, the robot will stop executing buy orders.
					<br/><br/>Minimum Position Size: If the current position size is smaller than that stated here, the robot will stop executing sell orders.
					<br/><br/>Operating Condition: Only works when orderbook is stuck, if box is checked.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start operation within 10 minutes of activating it while clicking the stop button will stop the robot immediately.</p>
				`,
			},
			// 2
			{
				name:'Batch Selling',//'Dividely sell robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The robot sells a large quantity of an asset in small batches. </p>
				<br/><p style="color: #9198AA;">
				According to the minimum selling price indicated, the robot will execute sell orders in intervals (seconds) as stated. The actual quantity of each order is a random value within 30% of the indicated quantity to ensure that overall size is hidden.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Clicking the start button activates the robot and when either the asset balance is insufficient or the price is below the floor price, the robot will stop.</p>
				`,
			},
			// 3
			{
				name:'Arbitrage Trading',//'Arbitrage robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function： </span>Does arbitrage trading across the selected exchange.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: Select the exchange to conduct the arbitrage trading.
					<br/><br/>Current Service Fee: Enter the current fee incurred by users for trades.
					<br/><br/>Target Service Fee: Enter the target fee to be incurred by users for trades.
					<br/><br/>Price Precision: Enter the number of decimal places allowed for price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},
			// 4
			{
				name:'Martingale',//'Martingale strategy quantify robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function： </span>At every configured level, Martingale will increase the position in volume according to the multiple indicated and reenter, averaging price.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>First Position Size: the opening amount of the first order, in {0}

					<br/><br/>Maximum times of Position additions: After opening a position, the total number of times to increase the position.(excluding open position)

					<br/><br/>Profit takne ratio: The unit is %.
					It is the percentage that the latest price is higher than the current average position price. When this value is reached, the profit will not be taken immediately, and the profit will only be taken when the price callbacks by a certain percentage.

					<br/><br/>Profit taken callback ratio: How much the price callback to sell positions after reaching the profit taken ratio.

					<br/><br/>Price spread of position addition: The unit is %, 
					calculated from the last purchase price. It determines how much the market continues to fall the robot will increase its position.
					When this value is reached, the position will not increase immediately unless the price rebound by a certain percentage.

					<br/><br/>Position addition callback ratio: the unit is %, after reaching the increase position ratio, how much the price rebounds before increasing position.

					<br/><br/>Double the first order: after the first order is purchased, if the price fluctuates within 1%, an additional purchase will be made. The amount is equal to the amount of the first order and is not included in the number of position additions.

					<br/><br/>Position addition multiple: how many times the amount of each position addition is the previous one.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. Once stopped, all pending orders will be cancelled.</p>
				`,
			},
			// 5
			{
				name:'Grid',//'Grid Startegy robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span> At every configured level, Grid will increase the position in volume according to the configured amount and reenter, averaging price down.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>First Position Size: The amount of first buy order.
					<br/><br/>Number of grids: maximum number of purchases (including the first time)
					<br/><br/>Profit taken ratio: The price interal of each profit taken order after each purchase.
					<br/><br/>Position addition interval(PAI): the unit is %. The price interval to place the next buy order after purchasing.
					<br/><br/>Margin interval increase of PAI: The gradient of the PAl, if number filled in is X greater than 1, the next Margin Interval will be magnified by X times.
					<br/><br/>Margin interval increase of position addition amount: the gradient of the amount placed for each position addition order. 
					If the number filled in is Y greater than 1, the next position addition amount will be multiplied by Y times.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. Once stopped, all pending orders will be cancelled.</p>
				`,
			},
			// 6
			{
				name:'Volume Generating',//'Match order robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates trading volume and increases market activity.</p>
				<br/><p style="color: #9198AA;">
					Orders will be executed with a random frequency and volume within the respective ranges stated.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked. If the market enters a consolidation phase, the robot will pause activities.</p>
				`,
			},
			// 7
			{
				name:'Orderbook Management',//'Orders management robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span> Controls the spread and increases the market depth. Improving liquidity of the market./p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Buy Order Volume: Volume of each buy order. Actual volume will be within a 30% deviation of volume stated.
					<br/><br/>Sell Order Volume: Volume of each sell order. Actual volume will be within a 30% deviation of volume stated.
					<br/><br/>Spread Size: Percentage difference between best bid and best ask.
					<br/><br/>Spread per Level: Percentage change in price between each level.
					<br/><br/>Price Precision: Decimal precision of price.
					<br/><br/>Number of Orders: Total number of orders used to support the price range. The default value is 20.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},
			// 8
			{
				name:'Support Zone',//'Price protector robot (buy)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates a support zone at the price range indicated.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price Range: Range of price to support.
					<br/><br/>Total Amount: Total amount of funds for supporting price at the price range stated.
					<br/><br/>Number of Orders: Total number of orders to execute to support price.
					<br/><br/>Price Precision: Decimal precision of price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once it has fulfilled all of the orders.</p>
				`,
			},
			// 9
			{
				name:'Resistance Zone',//'Price protector robot (sell)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Creates a resistance zone at the price range indicated.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations： </span>
					<br/><br/>Price Range: Range of price to resist.

					<br/><br/>Total Amount: Total amount of funds for resisting price at the price range stated.

					<br/><br/>Number of Orders: Total number of orders to execute to resist price.

					<br/><br/>Price Precision: Decimal precision of price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once it has fulfilled all of the orders.</p>
				`,
			},
			// 10
			{
				name:'Trend Setting',//'Trend push robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Trend setting used to stretch or hit plate, the plate will be according to set prices, the robot starts immediately to push the price to the position of "starting price", after work time interval for 5 ~ 10 minutes every time, and in setting the cut-off time reach the target price, if there are any retail interference, the robot will automatically to speed up or slow down the driving speed.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Total Amount: Total amount committed to enforcing trend.

					<br/><br/>End Time: Set a time for the robot to reach the target price
					<br/><br/>Start Price: Price of first order to start mechanism.

					<br/><br/>Target Price: Desired price for asset.
					<br/><br/>Price Precision: Decimal precision of price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked or stop once the target price has been met.</p>
				`,
			},
			// 11
			{
				name:'Huge Volume',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Within the set time, the robot will place a large order on the sell side of the market and quickly rebuy it.This function uses to generate considerable trading volume in a short time. Meanwhile, it also leads retail investors’ attention to the support and selling intentions at specific price zone.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Volume: The volume of transactions that need to be made by this robot per minute
					<br/><br/>Working hours: A few minutes
					<br/><br/>Split budget: The budget of capital consumption caused by transaction with users
					<br/><br/>Price Precision: Decimal precision of price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				`
			},{
				name:'Charting',//'Candlestick chart maintainer robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function： </span>Pushes prices up or down to develop the desired trend. Pending orders will be placed according to the frequency and price of orders.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Upper Limit: Highest price allowed for charting.

					<br/><br/>Lower Limit: Lowest price allowed for charting.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each tranche.

					<br/><br/>Quantity of Buy Orders: Number of buy orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Quantity of Sell Orders: Number of sell orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Max Quantity per Order: Maximum quantity each order will fulfill.

					<br/><br/>Price Precision: Decimal precision of price.

					<br/><br/>Total Daily Number of Orders: Maximum number of orders allowed in a day.

					<br/><br/>Index Follow: When this function is enabled, the charting robot will work with reference to the trends of the main market (like BTC, ETH, etc).
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Risk control: </span>If other users participate in the transaction, causing the price to break out of the set range, the robot will automatically stop working.</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Notice: </span>When the robot encounters a large order from the user account during running, The robot will identify it as a resistance zone set by the user and will not trade with these orders. Definition of large order: (order amount > 5 times of set quantity of buy and sell orders)</p>
				`,
			},{
				name:'Market Syncing (AltCoins)',//'Trading pair follower robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function： </span>Imitates the market movement of the token from another exchange. This imitation encompasses the Volume Generating and Orderbook Management functions. Thus, when Market Syncing is activated,  these 2 robots working on the same token will stop.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Mode: In standard mode, the robot will track according to the target disk price. In the hedging mode, the robot will realize the synchronization of price and liquidity at the same time through cross-hedging, which can effectively prevent cross-arbitrage.
					<br/><br/>Target Exchange: The exchange that the pair is listed on to Market Sync.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each tranche.

					<br/><br/>Quantity of Buy Orders: Number of buy orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Quantity of Sell Orders: Number of sell orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Maximum Volume: Maximum volume allowed per order.

					<br/><br/>Price Precision: Decimal precision of price.

					<br/><br/>Total Daily Number of Orders: Maximum number of orders allowed in a day.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>The robot will start and stop immediately according to the respective buttons clicked.</p>
				`,
			},{
				name:'Target Price',//'Market control robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Helps the asset achieve a desired price. This target will be met before 12am every night and a range oscillation will continue after on. The Volume Generating Robot must be used simultaneously to achieve a realistic trend.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Amount: The maximum budget to the target price, if the consumption exceeds the budget, the robot will automatically stop.

					<br/><br/>Target Price: At 0:00, the target price to reach by the robot

					<br/><br/>Spread Size: Spread Percentage required to keep between first buy order and first sell order. (here needs to fill an absolute value instead of a percentage)

					<br/><br/>Orders per tranche (bid-ask): Quantity of orders per tranche. This function of this robot supports customization, and there are three scales of orders for choosing to pending out, which are small, medium and large orders.

					<br/><br/>Spread per Level: Absolute value of price spread required to keep between each tranche of buy and sell orders.

					<br/><br/>Number of Levels: The total number of tranches required to maintain. 

					<br/><br/>Price Precision: Fill in the number of decimal places in the price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately. </p>
				`,
			},{
				name:'Market Syncing (Main Coins)',//'Trading pair follower robot (For mainstream tokens)',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It allows the current token to fluctuate with the price of the same-named token of another exchange. Since it includes matching order and orders management functions. When it is activated, The volume generating robot and orderbook management robot at the same trading pair will automatically terminate. It has own cross-platform hedging function. Working conditions: The market must have depth, or at least one pending order.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target Exchange: The exchange that the pair is listed on to Market Sync.

					<br/><br/>Spread Size: Percentage difference between best bid and best ask.

					<br/><br/>Spread per Level: Percentage change in price between each tranche.

					<br/><br/>Quantity of Buy Orders: Number of buy orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Quantity of Sell Orders: Number of sell orders to be maintained at each tranche. Actual size will be randomized within a 30% range from quantity indicated.

					<br/><br/>Maximum Volume: Maximum volume allowed per order.

					<br/><br/>Price Precision: Decimal precision of price.

					<br/><br/>Total Daily Number of Orders: Maximum number of orders allowed in a day.
					<br/><br/>Maximum Trade Volume: Largest Trade Volume allowed.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'DEX Charting',//'DEX candlestick chart maintainer robot',
				// , and the maximum is 600 seconds
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used to make sideways in a set price range and create trading volume. Furthermore,  increase the activity of the market and maintain the trend of the candlestick chart of the DEX.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price range: Fill in the numbers from low to high to control the line maintenance range. When the set range is exceeded, the robot will try its best to run within the set range.
					<br/><br/>Volume per deal: Fill in the numbers from low to high, the trading volume of each transaction will be a random range, the range can be set to a larger range to ensure that the transaction record looks like real users transaction.
					<br/><br/>Time interval: The time interval for each transaction. Due to the high cost of dex service fees and gas fees, it is recommended to fill in at least 30 seconds.
					<br/><br/>Trend direction: When the charting robot operates, it will defaultly refer to the price trend direction of the overall market.  This parameter can further adjust the direction of the price trend. 
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. 
				If transaction fails due to any reasons or the price difference between the buy and sell orders is extremely small which caused market stuck, the robot will pause work.</p>
				`,
			},{
				name:'DEX Market Syncing (AltCoins)',//'DEX trading pair follower robot',
				// , and the maximum is 600
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used in DEXs to control price follow the price of the CEX.The trading pair being followed can be any trading pair. Please note that control the price scaling parameters to ensure that the price operates within the expected range.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target exchange: The exchange of the target trading pair that needs to be followed.
					<br/><br/>Target symbol: The target symbol to follow.
					<br/><br/>Price scaling: Scales the price movement of the followed trading pair. The parameter supports any number greater than 0. If you fill in 1%, the price followed by the current token will be 1% of the target trading pair price. If you want the current token to run exactly at the target trading pair price, please set it to 100%.
					<br/><br/>Time interval: The time interval between each execution to follow price. The unit is seconds. It is recommended to be no less than 30 seconds,  because service fees at DEX and gas fees are high. it is not suitable for high-frequency trading.
					<br/><br/>Maximum volume per order: The maximum limit of each execution to follow price. If this volume is exceeded, it means that following the price needs to consume too many tokens. The robot will terminate working and report an error in the log. This parameter is used to secure your assets in unexpected situations.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'DEX Range Trading',//'DEX sell-high and buy-low robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Used to continuously buy low and sell high in a sideway markets. At the same time, it can control the price to prevent the price from being too high or too low, and repeatedly arbitrage in a sideways move.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>When the price higher than: When the price higher than the set price, the robot will sell.
					<br/><br/>When the price lower than: When the price lower than the set price, the robot will buy.
					<br/><br/>Time interval: It determines how often this robot works once.
					Please note that with higher the frequency, there will be higher the transaction cost and stricter control of the price. 
					Please configure it according to your requirements. It is recommended to work every 30 seconds or more.
					<br/><br/>Volume per order: The volume of each order after the set price is triggered. In order to make the transaction record look normal, the actual order volume will be a random value within the range of 50% above and below the set volume.
					<br/><br/>Maximum position: Assets security parameters. When the robot detects that the balance of the token is greater than or equal to the set maximum position, the robot will stop buying.
					<br/><br/>Minimum position: Assets security parameters. When the robot detects that the balance of the token is lower than or equal to the set minimum position, the robot will stop selling.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'DEX Snap Up',//'DEX snap up robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used to monitor the buying of large orders for the specified symbol. When the robot detected a large order to buy, it will make a deal with higher gas fee before the large order to complete the arbitrage.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Trigger volume: When the robot finds that a buy order exceeding the set volume has appeared but has not been closed, it will execute the buy action.
					<br/><br/> Buy in everytime: The specified buy amount when the buy action is triggered.
					<br/><br/>Whether to sell automatically: If this option is activated, the robot will sell immediately once the price rises after the order deal.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'DEX New Token Sniper',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>For newly created symbol in DEX, the initial price is always very low. So there is a great opportunity to rise. This robot can monitor the creation of the target symbol and buys it as soon as it is created.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Volume to buy : the volume to buy after the target symbol is created.
					<br/><br/>Whether to sell automatically: 
					If this option is activated, the robot will sell immediately once the price rises after order deal.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'Arbitrage From DEX To CEX',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Help user to arbitrage from DEX to CEX.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Target exchange: Select which exchange to arbitrage with the current exchange.
					<br/><br/>Current service fee: unit is %. if the current service fee is 0.1%, then fill in with 0.1.
					<br/><br/>Target service fee: unit is %. if the target service fee is 0.1%, then fill in with 0.1.
					<br/><br/>Price decimal characters: Fill in the number of decimal places in the price.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
			},{
				name:'NFT Charting',//'DEX candlestick chart maintainer robot',
				// , and the maximum is 600 seconds
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>It is used to make sideways in a set price range and create trading volume. Furthermore,  increase the activity of the market and maintain the trend of the candlestick chart of the DEX.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>Price range: Fill in the numbers from low to high to control the line maintenance range. When the set range is exceeded, the robot will try its best to run within the set range.
					<br/><br/>Volume per deal: Fill in the numbers from low to high, the trading volume of each transaction will be a random range, the range can be set to a larger range to ensure that the transaction record looks like real users transaction.
					<br/><br/>Time interval: The time interval for each transaction. Due to the high cost of dex service fees and gas fees, it is recommended to fill in at least 30 seconds.
					<br/><br/>Trend direction: When the charting robot operates, it will defaultly refer to the price trend direction of the overall market.  This parameter can further adjust the direction of the price trend. 
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. 
				If transaction fails due to any reasons or the price difference between the buy and sell orders is extremely small which caused market stuck, the robot will pause work.</p>
				`,
			},{
				name:'NFT Range Trading',//'DEX sell-high and buy-low robot',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Used to continuously buy low and sell high in a sideway markets. At the same time, it can control the price to prevent the price from being too high or too low, and repeatedly arbitrage in a sideways move.</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">Operations: </span>
					<br/><br/>When the price higher than: When the price higher than the set price, the robot will sell.
					<br/><br/>When the price lower than: When the price lower than the set price, the robot will buy.
					<br/><br/>Time interval: It determines how often this robot works once.
					Please note that with higher the frequency, there will be higher the transaction cost and stricter control of the price. 
					Please configure it according to your requirements. It is recommended to work every 30 seconds or more.
					<br/><br/>Volume per order: The volume of each order after the set price is triggered. In order to make the transaction record look normal, the actual order volume will be a random value within the range of 50% above and below the set volume.
					<br/><br/>Maximum position: Assets security parameters. When the robot detects that the balance of the token is greater than or equal to the set maximum position, the robot will stop buying.
					<br/><br/>Minimum position: Assets security parameters. When the robot detects that the balance of the token is lower than or equal to the set minimum position, the robot will stop selling.
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>Click the start button and the robot will start working immediately. Click the stop button and the robot will stop immediately.</p>
				`,
		},],
		showRunning:'Show Running Only',
		addrobot:'Add Robot',
		log:'Robot log',
		// 1
		higherthan:' Price Above ',
		sellout:' sell',
		below:' Price Below ',
		purchase:' buy',
		interval:'Order Interval ',
		second:' second',
		per:'Order Quantity ',
		opponents:'Match Opposing Quantity',
		maximum:'Maximum Position Size ',
		minimum:'Minimum Position Size ',
		working:'Operating Condition ',
		chuck:'Activate only when orderbook is stuck',
		// 2
		floorPrice:'Floor Price ',
		interval2:'Interval ',
		quantityPerTime:'Quantity ',
		frequency:'Number of Levels ',
		// 3
		targetExchange:'Target Exchange ',
		currentCharge:'Current Service Fee ',
		targetCharge:'Target Service Fee ',
		unit:'The unit is %',
		priceDecimal:'Price Precision ',
		quantityDecimal:'Quantity Precision ',
		// 4
		openingAmount:'First Position Size ',
		repTimes:'Times of Margin Calls ',
		interferenceRange:'Profit Taken Range ',
		repDifferences:'Price Difference of Margin Calls ',
		ci:'Times',

		stopBackRatio:'Profit Taken Callback Ratio',
		addBackRatio:'Position Addtion Callback Ratio',
		firstDouble:'Double The First Order',
		addMultiple:'Multiple of Position Addition',

		firstAmount:'First Position Size',
		// addNum:'Times of Position addition',
		stopRatio:'Profit Taken Ratio',
		// declineRatio:'Increase positions when the price drops by',
		// 5
		priceRange:'Price Range ',
		grids:'Number of Grids ',
		perGear:'Quantity Per Tranche ',
		maintain:'Buy Order Maintains ',
		stopPrice:'Stop Price ',
		more:'Go long',
		short:'Go short',

		addInterval:'PAI',//全称Position addition interval
		addIntervalIncrease:'Increase Ratio of PAI',
		addAmountIncrease:'Increase Ratio of Position Addition Amount',
		// 6
		counterQuantity:'Volume Range ',
		perMinute:'Orders Frequency (Per Minute) ',
		// 7
		orderPerNum:'Buy Order Volume ',
		sellPerNum:'Sell Order Volume ',
		difference:'Spread Size ',
		perDifference:'Spread per Level ',
		maintenance:'Number of Orders ',
		// 8 9
		protective:'Total Amount ',
		gear:'Number of Orders ',
		// 10
		buyBtn:'Buy',
		sellBtn:'Sell',
		plan:'Total Amount ',
		// intervalRange:'Range of Intervals ',
		random:' second',//random second
		// perInterval:'Price Change ',//Price interval for each push
		endTime:'End Time',
		startPrice:'Start Price ',
		targetPrice:'Target Price ',
		// 11
		tradingVolume:'Volume',
		workingHours:'Working hours',
		splitBudget:'Split budget',
		minute:'minute',
		minutes:'minute(s)',
		// 12
		upperLimit:'Upper Limit ',
		lowerLimit:'Lower Limit ',
		buy:'Quantity of Buy Orders ',
		offers:'Quantity of Sell Orders ',
		singleMax:'Max Quantity per Order ',
		swiping:'Total Daily Amount of Orders ',
		individual:'',//个
		indexFollow:'Index Follow',
		// 13
		standard:'Standard Mode',
		rebalance:'Hedging Mode',
		targetDisk:'Target Exchange ',
		// 14
		budget:' Amount ',
		target:'Target Price ',
		paySmall:'Buy Orders per Level (small) ',
		sellSmall:'Sell Orders per Level (small) ',
		gears:'Number of Levels ',
		payMedium:'Buy Orders per Level (medium) ',
		sellMedium:'Sell Orders per Level (medium) ',
		payLarge:'Buy Orders per Level (large) ',
		sellLarge:'Sell Orders per Level (large) ',
		// 15
		hop:'Maximum Trade Volume ',
		// 16
		transaction:'Volume per deal ',
		intervalTime:'Interval time ',
		direction:'Trend direction ',
		oscillation:'Make Sideway',
		rise:'Go rise',
		fall:'Go down',
		// 17
		targetPair:'Target symbol ',
		scaling:'Price scaling ',
		transactionMax:'Maximum volume per order ',
		// 19
		trigger:'Trigger volume ',
		everyPurchase:'Buy in everytime ',
		autoSell:'Whether to sell automatically ',//Whether to sell out automatically
		isAutoSell:'Sell automatically',//Sell out automatically
		// 20
		contract:'Target contract',
		orderAmount:'Order amount',
		multiple:'Profit multiple',
		openingTime:'Listing time',
		deduction:'Whether tax deduction',
		times:'Multiples',
		taxDeduction:'tax deduction',
		noTaxDeduction:'no tax deduction',


		success:'Robot added successfully',
		sub:'Added robots can be viewed at Trading robots',
		parameter:'Parameter settings',
		current:'Current Status',
		running:'Robots operational',
		stopped:'Robots terminated',
		robotEmpty:'There is no robot added, go to add',
		robotEmpty2:'No Robots Activated Currently.',
	},

	// 工具
	tools:{
		check:{
			name:'Place Buy Orders In Batch',//Batch Buy
			sub:'Assist user to place order between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to place order between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">According to the set price range and the total number of orders, this robot will divide the set price range into several tiers (the quantity is equal to the total number of orders), and then place orders at each tier price.

				The specific quantity of each order is a random quantity within the range of the pending quantity. 
				
				"Price range" needs to fill in the specific price. Note to fill the lower limit first, and then fill the upper limit; 
				
				"Volume" determines the random range of the volume of each buy or sell order;
				
				"Total Quantity" refers to the number of orders pending placed by the robot at one time.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order placing is finished.</p> 
			`,
		},
		sale:{
			name:'Place Sell Orders In Batch',//Batch Sell
			sub:'Assist user to place order between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to place order between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">According to the set price range and the total number of orders, this robot will divide the set price range into several tiers (the quantity is equal to the total number of orders), and then place orders at each tier price.

				The specific quantity of each order is a random quantity within the range of the pending quantity. 
				
				"Price range" needs to fill in the specific price. Note to fill the lower limit first, and then fill the upper limit; 
				
				"Volume" determines the random range of the volume of each buy or sell order;
				
				"Total Quantity" refers to the number of orders pending placed by the robot at one time.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order placing is finished.</p>  
			`,
		},
		revoke:{
			name:'Revoke Order In Batch',//Batch Cancellation
			sub:'Assist user to revoke all orders between a certain price range',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Assist user to revoke all orders between a certain price range.</p>
				<br/>
				<p style="color: #9198AA;">Revoke all users pending orders within a certain range according to the set price range. 

				"Price range" needs to fill in the specific price. Note to fill the lower limit of the range first, and then fill  the upper limit.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when order revoking is finished.</p> 
			`,
		},
		calculate:{
			name:'DEX Trading Volume Measurement',
			sub:'Calculates the volume required for the price to rise or fall to a specified price. Or enter a volume to know where the price will move to',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Calculates the volume required for the price to rise or fall to a specified price. Or enter a volume to know where the price will move to.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;"Operations: </span>
				<br/>Target price: Enter the expected target price and the tool will calculate how much volume is required. The trading volume parameter can be left blank.
				<br/>Trading volume required: Enter the expected volume and the tool will calculate where the price will move to. The price parameter can be left blank.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Start/Stop: </span>After the parameters are filled in, click the "Submit" button, the robot will work immediately, and stop when calculation is finished.</p> 
			`,
		},
		transaction:{
			name:'DEX Place Order In Batches',
			sub:'Use all sub-wallets of DEX for bulk buy and sell operations',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Use all sub-wallets of DEX for bulk buy and sell operations.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;"> Operations: </span>
				<br/>Wallets number: Enter the number of wallets you want to order.
				<br/>Trading volume: Enter the total trading volume. For symbol A-B, this parameter represents how much B will be spent when buying, and how much A will be sold when selling.
				<br/>Floating range: you can control the number of orders per wallet according to the floating ratio. Order quantity range: 【(order total/order number of wallets)* minimum floating ratio ~ (order total/order number of wallets)* maximum floating ratio】, such as floating ratio 40%, order quantity range: 【(order total/order number of wallets)*60% ~ (order total/order number of wallets)*140%】
				<br/>Trading types: buy, or sell.</p>
			`,
		},
		balance:{
			name:'DEX View Sub-wallets Balance',
			sub:'Calculate asset balances information in all sub-wallets, and  allow to manually refresh sub-wallet balance',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Help user to calculate asset balances information in all sub-wallets, and  allow to manually refresh sub-wallet balance when there is issue during obtaining balance. </p>
			`,
		},
		wallet:{
			// name:'DEX sub-wallet creation tool',
			name:'Sub-wallet Create',
			sub:'Create sub-wallets for placing orders in batches',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function:</span>Create sub-wallets for placing orders in batches.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations:</span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Number of sub-wallets: fill in the number, which represents how many sub-wallets to create
				<br/>Transaction restriction:
				<br/>&nbsp;&nbsp;Unlimited (Selected wallet can place both buy and sell orders)
				<br/>&nbsp;&nbsp;Only buy (Selected wallet can only place buy orders.)
				<br/>&nbsp;&nbsp;Only sell (Selected wallet can only place sell orders.)
				</p>
			`,
		},
		distribution:{
			// name:'DEX allocate assets',
			name:'Allocate Assets',
			sub:'The specified assets can be allocated from the bound main wallet to the sub-wallet created by the sub-wallet creation tool',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>The specified assets can be allocated from the bound main wallet to the sub-wallet created by the sub-wallet creation tool.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Token: The abbreviation of the token's name to be allocated, such as USDT, 
				<br/>Total amount: The total amount of tokens to be allocated.
				<br/>Allocation type:
				<br/>&nbsp;&nbsp;1- Random allocation (random proportion allocated to each sub-wallet, may allocate a large floating gap)
				<br/>&nbsp;&nbsp;2- Average floating (according to the floating range you input, the funds allocated to each wallet will be allocated around the average, 0% is the average allocation, >=80% will be allocated according to the random strategy)
				<br/>&nbsp;&nbsp;3- Conditional allocation (Filter the wallet to be allocated and allocate according to the criteria you entered)
				</p>
			`,
			// <br/>钱包少于:设置需要接受分拨的钱包的资产条件</p>
			// <br/>浮动范围:每个钱包平均分拨</p>
		},
		pool:{
			// name:'DEX collect assets',
			name:'Collect Assets',
			sub:'Collect specified assets from sub-wallets into the main wallet',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>Collect specified assets from sub-wallets into the main wallet.</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Chain name: The chain where wallets are created, such as BSC, MATIC, OEC, HECO, etc.
				<br/>Token: The abbreviation of the token's name to be collected, such as USDT, BNB, etc.
				<br/>Total amount: The total amount of tokens required to be collected.</p>
			`,
		},
		empower:{
			name:'DEX Wallets Authorization',
			sub:'Authorize the main account or sub-wallets attached to the main account to trade in a certain token',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Function: </span>With this tool, authorize the main account or sub-wallets attached to the main account to trade in a certain token</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">Operations: </span>
				<br/>Authorize token: Enter token value</p>
			`,
			// <br/>Authorize type: Sub-wallet authorization, Main wallet authorization.
		},
		// dex子钱包管理
		manage:{
			name:'DEX Wallets Manage',
			sub:'Create DEX sub wallets, DEX Allocate Assets, DEX Collect Assets',
		},
		// dex子钱包刷新
		refresh:{
			name:'Refresh Sub-wallets',
			sub:'Manually refresh the sub-wallets balance information',
		},
		// 导入子钱包
		importWallet:{
			name:'Import Sub-wallets',
		},
		// NFT钱包管理
		manageNFT:{
			name:'NFT Wallets Manage',
			sub:'Create wallets, Allocate Assets, Collect Assets',
		},
	},

	// 数据报表
	data:{
		recent7:'Last 7 days',
		recent30:'Last 30 days',
		allocation:'Asset Allocation',
		dailyTrading:'Daily trading volume',
		gas:'Service fee',
		buy:'Buy',
		sell:'Sell',
		curve:'Asset Trend',
		nftWait:'NFT is not currently supported, please look forward to it!',
		nftWait2:'Do not support NFT exchange data statistics, please look forward to!',
		endTime:'The chart data ends at ',
		export:'Export',
	},
	// 资产报表
	property:{
		propertyEmpty:'No reports found',//'No asset report yet',
		selectTime:'Time Range',
		selectTimeTip:'Please select a time range',
		option1:'1 month',
		option2:'3 months',
		option3:'6 months',
	},

	// 报警系统
	alarmSystem:{
		addAlarm:'New Alert',
		telegram:'Contact us on Telegram',//Bind to telegram
		parameter:'Settings',
		currentState:'Current Status',
		operation:'Alert operational',
		stopped:'Alert terminated',
		noData:'No alerts available',//'No alarm system activated yet',
		priceThan:'When price higher than ',
		priceBelow:'When price lower than ',
		less:' less than ',
		mutation :'Extent of price change ',
		conditions:'Arbitrage Robot Detection',
		addTrigger:'Add New Alert',
		editTrigger:'Edit Alert',//'Edit an alarm',
	},

	// 续费开通
	renew:{
		account:'Personal account',
		balance:'Account balance',
		balanceDetail:'Balance Details',
		vipNow:'Current package',
		remaining:'{time} left',
		expired:'Expired',
		remainingMonth:' month ',
		remainingDay:' day',
		remainingMonths:' months ',
		remainingDays:' days',
		continueTime:'Renewal time',
		contact:'Customer Service',
		renew:'Renew',

		opentime:'Release time',
		month:'month(s)',//month
		individual:'',//个
		month2:' month(s)',//month
		openings:'Number of activated trading pairs',
		activate:'Activate permission',//Activate //Get permission/ Activate permission
		switchPackage:'Switch package',
		// confirmation:'Information confirmation',
		confirmation1:'Information confirmation (Renew)',
		confirmation2:'Information confirmation (Switch package)',
		grade:'Account level',
		validity:'Valid after recharge',
		openingsTip:'（Number of activated trading pairs refers to how many trading pairs that robots can work at the same time）',
		cost:'Cost',
		activityCost:'Activity price: ',
		stillNeed:'Still to be paid',
		refund:'Refund',
		refundTip:'Refund to your Light-wallet',//Tips: 
		rechargeNeed:'Need to recharge',
		rechargePay:'Recharge and Renewal',
		rechargePayTip1:"【Note: After clicking 'Recharge and Renewal', your wallet will be temporarily locked. After detecting your recharge action, the system will automatically renew and then unlock your wallet. (If you want to stop this operation and unlock the wallet, please contact customer service.)】",//充值支付提示-续费
		rechargePayTip2:"【Note: After clicking 'Recharge and Renew', your wallet will be temporarily locked. After detecting your recharge action, the system will automatically switch to the plan you modified and unlock your wallet. (If you want to stop this operation and unlock the wallet, please contact customer service.)】",//充值支付提示-切换套餐

		package:'Package benefits',
		include:'Include ',
		origin:'Original ',

		subBalance:' balance',
		// 充值
		rechargeType:'Chain to recharge from',
		rechargeAddress:'Recharge address',
		copyAddress:'Copy',
		tip:'Note',
		rechargeTip1:'Please confirm only USDT ( {type} ) is send to this address. If other tokens are sent to this address, they will not be retrieved and there is no compensation. After the block is confirmed, system would inform that deposit success.',
		// rechargeTip2:'Light-wallet currently does not support the recharge function, please understand the inconvenience brought to you, if you need to provide help, please contact the business customer service.',
		renewRecord:'Recharge and renew records',
		only5:'（Only show the most recent 5 records）',
		only10:'（Only show the most recent 10 records）',
		renewAddress:'Address',
		renewAmount:'Amount',
		renewTime:'Time',
		renewSource:'Chain',
		renewState:'Status',
		renewSuc:'Success',
		endTime:'Expiration time (Account)',
		blockQuery:'Block query',
		// 提现
		address:' address',
		// total:'Total balance',
		min:'Minimum withdraw amount',
		service:'Service fee',
		withdrawalType:'Wallet',
		withdrawalChain:'Chain to withdraw to',
		withdrawalAmount:'Withdraw amount',
		withdrawalHolder:'Minimum 50 USDT, please fill in a number',
		withdrawalAddress:'Withdraw address',
		withdrawalTip1:"Please confirm to fill in the correct USDT ( {type} ) For any assets lost caused by users' personal issues, LIGHT will not take any responsibility. These issues can be conclued but not limited as followings: the withdraw address you entered is not your own wallet address; or any necessary parameters are not filled in exactly; etc. In order to ensure the safety of users' assets, large withdrawals require manual review which usually within 10-30 minutes.",
		// withdrawalTip2:'Light-wallet currently does not support the withdrawal function, please understand the inconvenience brought to you, if you need to provide help, please contact the business customer service.',
		withdrawalTip2:"Please confirm to fill in the correct USDT For any assets lost caused by users' personal issues, LIGHT will not take any responsibility. These issues can be conclued but not limited as followings: the withdraw address you entered is not your own wallet address; or any necessary parameters are not filled in exactly; etc. In order to ensure the safety of users' assets, large withdrawals require manual review which usually within 10-30 minutes.",
		withdrawalRecord:'Withdraw records',
		withdrawalTime:'Withdraw time',
		currentState:'Current Status',
	},
	// 续费时长
	renewTime:[
		'Month',
		'Quarter',
		'Half a year',
		'Year'
	],
	renewTime2:[
		'Month',
		'Quarter (20% off)',
		'Half a year (30% off)',
		'Year (40% off)'
	],
	// 充值续费提现状态
	state:[
		'Background Renewal successful',//0
		'Renewal is processing',//1
		'Renewal successful',//2
		'Renewal failed',//3
		'Switch package is processing',//4
		'Switch package successful',//5
		'Switch package failed',//6
		'Under application',//7
		'Success',//8
		'Failed',//9
		'Withdrawal approved',//10
		'Recharge is processing',//11
		'Recharge successful',//12
		'Recharge failed',//13
	],
	// 等级
	level:[
		'Individual Version Package',
		'Enterprise Version Package',
		'Professional Version Package',
		'DEX Version Package',
		'NFT Version Package',
	],

	// 用户中心
	user:{
		data:'Account Information',
		userName:'Username',
		lv:'Level',
		upORren:'Upgrade/Recharge',
		autoRenew:'Automatic renewal',
		autoRenewTip:'Automatic renewal: When the account is valid for 3 days remaining, and the balance of your Lightning Wallet meets the fee of the current package, the renewal will automatically proceed.',
		help:'Enable Tutorial',//Guide
		security:'Security',
		modifyNum:'Change Phone number',
		changePwd:'Change Password',
		bindEmail:'Bind Email',
		changeEmail:'Change Email',
		safeLogin:'Enable Email Verification',//'Secure Login',
		ipVer:'IP Verification',
		ipRecommend:'Not recommended close',
		
		language:'Language',
		changeLanguage:'Change Language',

		model:'Model',
		dark:'Dark',
		light:'Light',//日间模式
	},

	// 邀请好友
	invite:{
		code:'Referral Code',
		link:'Referral Link',
		rules:'Referral Rules',
		rules1:'Refer your friends via the invitation link or code and assist them in starting a robot.',
		rules2:'You will receive 20% of the revenue generated.',
		revenue:'Total Rewards',
		today:'Number of referrals today',
		total:'Total number of referrals',
		record:'Referral Records',
		account:'Account',
		money:'Amount',
		pay:'Payment Date',
	},

	// 404
	notFount:{
		msg:'Sorry, this page is lost.',
		backBtn:'Navigate back home',
	},
}
