import { login, logout, getInfo,safeLoginCode } from '@/api/user'
import { 
  getToken, setToken, removeToken ,
  getAccount, setAccount, removeAccount 
} from '@/utils/auth'

const state = {
  token: getToken(),
  account: getAccount(),
  type: '',//等级
  available_time:'',//有效时间
  // tel:'',
  telegram:'',
  // contact_type:0,//0-手机号 1-电报
  email:'',
  safe_login:'',
  ip_check:'',
  code: '',
  invite_url: '',
  pay_set:'',//自动续费

  // needHelp:localStorage.getItem("needHelp")||'1',//首次登录是否需要帮助
  isFirstLogin:localStorage.getItem("isFirstLogin")||'1',//是否是首次登录
	novice:localStorage.getItem('novice')||'1',//是否是新手
  helpNum:1,// 新手引导进度1-4

  // 安全登录
  safe_account:localStorage.getItem("safe_account")||'',
  safe_email:localStorage.getItem("safe_email")||'',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, account) => {
    state.account = account
  },
  SET_TYPE: (state, type) => {
    state.type = type
  },
  SET_AVAILABLETIME: (state, available_time) => {
    state.available_time = available_time
  },
  // SET_TEL: (state, tel) => {
  //   state.tel = tel
  // },
  SET_TELEGRAM: (state, telegram) => {
    state.telegram = telegram
  },
  // SET_CONTACT_TYPE: (state, contact_type) => {
  //   state.contact_type = contact_type
  // },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_SAFE_LOGIN: (state, safe_login) => {
    state.safe_login = safe_login
  },
  SET_IP_CHECK: (state, ip_check) => {
    state.ip_check = ip_check
  },

  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_INVITE: (state, invite_url) => {
    state.invite_url = invite_url
  },
  //首次登录是否需要帮助
  // SET_NEED_HELP: (state, needHelp) => {
  //   state.needHelp = needHelp
  // },
  // 是否首次登录
  SET_IS_FIRST_LOGIN: (state, isFirstLogin) => {
    state.isFirstLogin = isFirstLogin
  },
  //是否是新手
  SET_NOVICE: (state, novice) => {
    state.novice = novice
  },
  // 新手引导进度
  SET_HELP_NUM: (state, helpNum) => {
    state.helpNum = helpNum
  },
  // 是否自动续费
  SET_PAY_SET: (state, pay_set) => {
    state.pay_set = pay_set
  },

  // 安全登录
  SET_SAFE_ACCOUNT: (state,safe_account) => {
    state.safe_account = safe_account
  },
  SET_SAFE_EMAIL: (state,safe_email) => {
    state.safe_email = safe_email
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { account, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password }).then(response => {
        const data = response
      
        // 特殊账户给予特定交易所交易对缓存
        if(data.user_name=='lightTest1'||data.user_name=='lightTest2'||data.user_name=='lightTest3'){
          if(!localStorage.getItem('exchange')||!localStorage.getItem('web')){
            localStorage.setItem('exchange',localStorage.getItem('lang')=='zh'?'Pancake':'pancake')
            localStorage.setItem('web','pancake')
            // cake bnb
            localStorage.setItem('yenUnit1','CAKE')
            localStorage.setItem('yenUnit2','BNB')
            localStorage.setItem('web_id','125')
            localStorage.setItem('web_type','1')
          }
        }

        // 登录后缓存token及用户名
        commit('SET_TOKEN', data.login_token)
        commit('SET_NAME', data.user_name)
        setToken(data.login_token)
        setAccount(data.user_name)
        // 存储登陆时间
        var login_time=new Date().getTime()
        localStorage.setItem('login_time',login_time)

        //首次登录是否需要帮助
        // commit('SET_NEED_HELP', '1')
        // localStorage.setItem('needHelp', '1');
        // 是否是首次登录
        commit('SET_IS_FIRST_LOGIN', '1')
        localStorage.setItem('isFirstLogin', '1');
        // 初始化新手引导进度
        commit('SET_HELP_NUM', 1)

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo({account:state.account}).then(response => {
        const data = response
        const { user_name,type,
          // tel,
          telegram,available_time,code,invite_url,email,safe_login,ip_check,pay_set } = data
        // ,contact_type
        commit('SET_NAME', user_name)
        commit('SET_TYPE', type)
        commit('SET_AVAILABLETIME',available_time)
        // commit('SET_TEL', tel)
        commit('SET_TELEGRAM', telegram)
        // commit('SET_CONTACT_TYPE', contact_type)//0-手机号 1-电报
        commit('SET_EMAIL', email)
        commit('SET_SAFE_LOGIN', safe_login)
        commit('SET_IP_CHECK', ip_check)
        commit('SET_CODE',code)
        commit('SET_INVITE', invite_url)
        commit('SET_PAY_SET', pay_set)
        // console.log(data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout({account:state.account}).then(res => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        removeToken()
        removeAccount()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      removeToken()
      removeAccount()
      resolve()
    })
  },

  // 安全登录
  safeLogin({ commit, state },sms_code){
    return new Promise((resolve, reject) => {
      safeLoginCode({ 
        account: state.safe_account.trim(), 
        email: state.safe_email, 
        sms_code
      }).then(response => {
        const data = response
        
        // 特殊账户给予特定交易所交易对缓存
        if(data.user_name=='lightTest1'||data.user_name=='lightTest2'||data.user_name=='lightTest3'){
          if(!localStorage.getItem('exchange')||!localStorage.getItem('web')){
            localStorage.setItem('exchange',localStorage.getItem('lang')=='zh'?'Pancake':'pancake')
            localStorage.setItem('web','pancake')
            // cake bnb
            localStorage.setItem('yenUnit1','CAKE')
            localStorage.setItem('yenUnit2','BNB')
            localStorage.setItem('web_id','125')
            localStorage.setItem('web_type','1')
          }
        }

        // 登录后缓存token及用户名
        commit('SET_TOKEN', data.login_token)
        commit('SET_NAME', data.user_name)
        setToken(data.login_token)
        setAccount(data.user_name)
        // 存储登陆时间
        var login_time=new Date().getTime()
        localStorage.setItem('login_time',login_time)
        
        //首次登录是否需要帮助
        // commit('SET_NEED_HELP', '1')
        // localStorage.setItem('needHelp', '1');
        // 是否是首次登录
        commit('SET_IS_FIRST_LOGIN', '1')
        localStorage.setItem('isFirstLogin', '1');
        // 初始化新手引导进度
        commit('SET_HELP_NUM', 1)
        
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  setSafeAccount({ commit },safe_account) {
    localStorage.setItem("safe_account",safe_account)
    commit('SET_SAFE_ACCOUNT',safe_account)
  },
  setSafeEmail({ commit },safe_email) {
    localStorage.setItem("safe_email",safe_email)
    commit('SET_SAFE_EMAIL',safe_email)
  },
  
  // 开/关 新手引导
  setNovice({ commit },novice) {
    localStorage.setItem('novice',novice)
    commit('SET_NOVICE',novice)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}