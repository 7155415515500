// import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'

export function getToken() {
  // return Cookies.get(TokenKey)
    return localStorage.getItem("token");
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  try{
    localStorage.setItem('token', token)
  }catch (err) {
    // 处理空间不足的情况
    localStorage.removeItem("token");
    localStorage.setItem('token', token)
  };
  // return localStorage.setItem('token', token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.removeItem("token");
}

// 用户账号
export function getAccount() {
  return localStorage.getItem("account");
}
  
export function setAccount(account) {
  try{
    localStorage.setItem('account', account)
  }catch (err) {
    // 处理空间不足的情况
    localStorage.removeItem("account");
    localStorage.setItem('account', account)
  };
  // return localStorage.setItem('account', account);
}

export function removeAccount() {
  return localStorage.removeItem("account");
}