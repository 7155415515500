// 市场相关
import { 
    getWebList,
    getCollectList, 
} from '@/api/markets'
// import {getVipList,getPayInfo} from '@/api/user'
import {getVipList} from '@/api/user'

const state = {
    webList: [],
    collectList: [],
    vipList: {},
}

const mutations = {
    SET_WEB_LIST: (state, webList) => {
        state.webList = webList
    },
    SET_COLLECT_LIST: (state, collectList) => {
        state.collectList = collectList
    },
    SET_VIP_LIST: (state, vipList) => {
        state.vipList = vipList
    },
}
const actions = {
    getList({commit,rootState}){
        return new Promise((resolve, reject) => {
            getWebList({account:rootState.user.account}).then(res=>{
                commit('SET_WEB_LIST',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getCollectList({commit,rootState}){
        return new Promise((resolve, reject) => {
            getCollectList({account:rootState.user.account}).then(res=>{
                commit('SET_COLLECT_LIST',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // getPayInfo({commit,rootState}){
    //     return new Promise((resolve, reject) => {
    //         getPayInfo({account:rootState.user.account}).then(res=>{
    //             commit('SET_VIP_LIST',res.fee)
    //             resolve(res)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    getVipList({commit}){
        return new Promise((resolve, reject) => {
            getVipList({}).then(res=>{
                commit('SET_VIP_LIST',res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}