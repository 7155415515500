const getters = {
    // user
    token: state => state.user.token,
    account: state => state.user.account,
    type: state => state.user.type,
    available_time: state => state.user.available_time,
    // tel: state => state.user.tel,
    telegram: state => state.user.telegram,
    // contact_type: state => state.user.contact_type,
    email: state => state.user.email,
    safe_login: state => state.user.safe_login,
    ip_check: state => state.user.ip_check,
    code: state => state.user.code,
    invite_url: state => state.user.invite_url,
    pay_set: state => state.user.pay_set,
    // needHelp: state => state.user.needHelp,
    isFirstLogin: state => state.user.isFirstLogin,
    novice: state => state.user.novice,
    helpNum: state => state.user.helpNum,
    // 安全登录
    safe_account: state => state.user.safe_account,
    safe_email: state => state.user.safe_email,
    // markets
    webList: state => state.markets.webList,
    collectList: state => state.markets.collectList,
    vipList: state => state.markets.vipList,
    // account
    // accountList: state => state.account.accountList,
    // asideChilder: state => state.account.asideChilder,

    // app
    isCollapse: state => state.app.isCollapse,
    device: state => state.app.device,
    lang: state => state.app.lang,
    isSmall: state => state.app.isSmall,
    isMobile: state => state.app.isMobile,
    theme: state => state.app.theme,

    version: state => state.app.version,
    isTwitter: state => state.app.isTwitter,

    ip: state => state.app.ip,
  }
  export default getters
  