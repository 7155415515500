import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

// 解决replace定向/问题
const originalPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: () => import('@/views/home.vue'),
		meta:{
			requireAuth:true,
		},
		redirect: '/light',
		children: [
			{
				path: '/account',
				name: 'Account',
				component: () => import('@/views/account/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[0]',
				},
			},
			{
				path: '/stocks',
				name: 'Stocks',
				component: () => import('@/views/stocks/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[1]',
				},
			},
			{
				path: '/stocksFull',
				name: 'stocksFull',
				component: () => import('@/views/stocks/stocksFull.vue'),
				meta:{
					requireAuth:true,
					title:'stocks.advanced',
				},
			},
			{
				path: '/subManage',
				name: 'SubManage',
				component: () => import('@/views/stocks/subManage.vue'),
				meta:{
					requireAuth:true,
					title:'common.subManage',
				},
			},
			{
				path: '/record',
				name: 'Record',
				component: () => import('@/views/stocks/record.vue'),
				meta:{
					requireAuth:true,
					title:'common.transactionRecord',
				},
			},
			{
				path: '/robot',
				name: 'Robot',
				component: () => import('@/views/robot/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[2]',
				},
			},
			{
				path: '/addrobot',
				name: 'robotadd',
				component: () => import('@/views/robot/add.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[2]',
				},
			},
			{
				path: '/robotlog',
				name: 'robotlog',
				component: () => import('@/views/robot/log.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[3]',
				},
			},
			{
				path: '/data',
				name: 'Data',
				component: () => import('@/views/data/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[4]',
				},
			},
			{
				path: '/property',
				name: 'Property',
				component: () => import('@/views/property/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[5]',
				},
			},
			{
				path: '/alarm',
				name: 'Alarm',
				component: () => import('@/views/alarm/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[6]',
				},
			},
			{
				path: '/renew',
				name: 'Renew',
				component: () => import('@/views/user/renew.vue'),
				meta:{
					requireAuth:true,
					title:'aside.renew',
				},
			},
			{
				path: '/recharge',
				name: 'Recharge',
				component: () => import('@/views/user/recharge.vue'),
				meta:{
					requireAuth:true,
					title:'common.recharge',
				},
			},
			{
				path: '/renewRecord',
				name: 'renewRecord',
				component: () => import('@/views/user/mobile/renewRecord.vue'),
				meta:{
					requireAuth:true,
					title:'renew.renewRecord',
				},
			},
			{
				path: '/withdrawal',
				name: 'Withdrawal',
				component: () => import('@/views/user/withdrawal.vue'),
				meta:{
					requireAuth:true,
					title:'common.withdrawal',
				},
			},
			{
				path: '/withdrawalRecord',
				name: 'withdrawalRecord',
				component: () => import('@/views/user/mobile/withdrawalRecord.vue'),
				meta:{
					requireAuth:true,
					title:'renew.withdrawalRecord',
				},
			},
			{
				path: '/user',
				name: 'User',
				component: () => import('@/views/user/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[8]',
				},
			},
			// 用户中心子页面*3-移动端
			{
				path: '/centerUser',
				name: 'centerUser',
				component: () => import('@/views/user/mobile/centerUser.vue'),
				meta:{
					requireAuth:true,
					title:'user.data',
				},
			},
			{
				path: '/centerSafe',
				name: 'centerSafe',
				component: () => import('@/views/user/mobile/centerSafe.vue'),
				meta:{
					requireAuth:true,
					title:'user.security',
				},
			},
			{
				path: '/centerLang',
				name: 'centerLang',
				component: () => import('@/views/user/mobile/centerLang.vue'),
				meta:{
					requireAuth:true,
					title:'user.language',
				},
			},
			{
				path: '/centerTheme',
				name: 'centerTheme',
				component: () => import('@/views/user/mobile/centerTheme.vue'),
				meta:{
					requireAuth:true,
					title:'user.model',
				},
			},
			{
				path: '/invite',
				name: 'Invite',
				component: () => import('@/views/user/invite.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[9]',
				},
			},
			// 邀请记录-移动端
			{
				path: '/inviteRecord',
				name: 'InviteRecord',
				component: () => import('@/views/user/mobile/inviteRecord.vue'),
				meta:{
					requireAuth:true,
					title:'invite.record',
				},
			},
			{
				path: '/course',
				name: 'Course',
				component: () => import('@/views/support/course.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[11]',
				},
			},
			{
				path: '/contact',
				name: 'Contact',
				component: () => import('@/views/support/contact.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[12]',
				},
			},
		]
	},
	// 官网系列
	{
		path: '/home',
		component: () => import('@/views/officialWebsite/home.vue'),
		redirect: '/light',
		children: [
			{
				path: '/light',
				name: 'light',
				component: () => import('@/views/officialWebsite/index.vue'),
				meta:{
					title:'common.title',
				},
			},
			// {
			// 	path: '/lightZH',
			// 	name: 'lightZH',
			// 	component: () => import('@/views/officialWebsite/indexZH.vue'),
			// 	meta:{
			// 		title:'common.title',
			// 	},
			// },
			{
				path: '/agreement',
				name: 'agreement',
				component: () => import('@/views/officialWebsite/agreement.vue'),
				meta:{
					title:'website.agreement.protocol',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'User Agreements'},
					// 	// og
					// 	{name:'og:title',content:'User Agreements'},
					// 	// twitter
					// 	{name:'twitter:title',content:'User Agreements'},
					// ],
				},
			},
			{
				path: '/problem',
				name: 'problem',
				component: () => import('@/views/officialWebsite/problem.vue'),
				meta:{
					title:'website.index.problem',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'Common Issues'},
					// 	// og
					// 	{name:'og:title',content:'Common Issues'},
					// 	// twitter
					// 	{name:'twitter:title',content:'Common Issues'},
					// ],
				},
			},
			// {
			// 	path: '/problemZH',
			// 	name: 'problemZH',
			// 	component: () => import('@/views/officialWebsite/problemZH.vue'),
			// 	meta:{
			// 		title:'website.index.problem',
			// 		// metaData:[
			// 		// 	...commonMetaData,
			// 		// 	// common
			// 		// 	{name:'title',content:'Common Issues'},
			// 		// 	// og
			// 		// 	{name:'og:title',content:'Common Issues'},
			// 		// 	// twitter
			// 		// 	{name:'twitter:title',content:'Common Issues'},
			// 		// ],
			// 	},
			// },
			{
				path: '/homeContact',
				name: 'homeContact',
				component: () => import('@/views/officialWebsite/homeContact.vue'),
				meta:{
					title:'common.contact',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'Contact us'},
					// 	// og
					// 	{name:'og:title',content:'Contact us'},
					// 	// twitter
					// 	{name:'twitter:title',content:'Contact us'},
					// ],
				},
			},

			{
				path: '/login',
				name: 'login',
				component: () => import('@/views/login/sign.vue'),
				meta:{
					title:'common.login',
				},
			},
			{
				path: '/safeLogin',
				name: 'safeLogin',
				component: () => import('@/views/login/safeLogin.vue'),
				meta:{
					title:'login.safeLogin',
				},
			},
			{
				path: '/forget',
				name: 'forget',
				component: () => import('@/views/forget/forget.vue'),
				meta:{
					title:'login.reset',
				},
			},
			{
				path: '/register',
				name: 'register',
				component: () => import('@/views/register/register.vue'),
				meta:{
					title:'login.register',
				},
			},
		]
	},
	{
		path: '/twitter',
		name: 'twitter',
		component: () => import('@/views/source/twitter.vue')
	},
	{
		path: '/qrcode',
		name: 'qrcode',
		component: () => import('@/views/source/qrcode.vue')
	},
	{ path: '*', component: () => import('@/views/notFound.vue')}
]

const router = new VueRouter({
	// history: createWebHistory(),
	// mode: 'hash',
	
	mode: 'history',
	// base:'/lightTest/',
	// base:'/light_vue/',
	routes,
})

router.beforeEach((to, from, next) => {
	// if (window.vm&&to.meta.title) {
	// 	document.title = window.vm.$t(to.meta.title);
	// }
	var lang=localStorage.getItem('lang') || 'en'
	// 如果去light页面判定语言是否为中文，中文则跳至中文页面否则进入常规首页（light）
	// if(to.path=='/light'){
	// 	if(lang=='zh'){
	// 		next('/lightZH')
	// 	}else{
	// 		next()
	// 	}
	// }else if(to.path=='/lightZH'){
	// 	if(lang!='zh'){
	// 		next('/light')
	// 	}else{
	// 		next()
	// 	}
	// }
	// 如果去problem页面判定语言是否为中文，中文则跳至中文页面否则进入常规页
	// if(to.path=='/problem'){
	// 	if(lang=='zh'){
	// 		next('/problemZH')
	// 	}else{
	// 		next()
	// 	}
	// }else if(to.path=='/problemZH'){
	// 	if(lang!='zh'){
	// 		next('/problem')
	// 	}else{
	// 		next()
	// 	}
	// }
	
	if (to.meta.requireAuth) {
		// if (hastoken) {
		if (!!getToken()) {
			// 是否有测试权限要求
			// if(to.meta.testMode){
			// 	if(localStorage.getItem("account")=='guanxiny1'){
			// 		next()
			// 	}else{
			// 		router.replace({
			// 			path: '/notFound',
			// 		})
			// 	}
			// }else{
				next()
			// }
		} else {
			// alert('请登录')
			router.replace({
				path: '/login',
				query: {redirect: to.fullPath}
			})
			next();
		}
	} else {
        var login_time=localStorage.getItem('login_time')
		var validity=login_time+24*3600*1000
        var login_time_now=new Date().getTime()
		// 如果进入不需要登录权限的页面时
		// 有存储的登陆时间且与当前时间一致（即当天）且有token
		// 则免登陆
		if(to.path=='/register'){//如果进注册（邀请链接），则保留登录状态进注册页，若注册成功清除登录状态
			next()
		}else{
			if(!!login_time&&login_time_now<validity&&!!getToken()){
				router.replace({
					path: '/stocks',
				})
			}
			next()
		}
	}
})

export default router
